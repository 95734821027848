import { ApiUrl, getMetadata } from '../index'
import { ListCustomersRequest, GetCustomerRequest, CreateCustomerRequest, UpdateCustomerRequest, DeleteCustomerRequest } from './customers_pb'
import { Customer } from './customer_pb'
import { CustomersClient } from './customers_grpc_web_pb'

let client = new CustomersClient(ApiUrl, null, null);

const API = {
  listCustomers: (params) => {
    const request = new ListCustomersRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      request.setSearch(params.search)
    }
    if (params?.companyDisplayName) {
      request.setCompanyDisplayName(params.companyDisplayName)
    }
    if (params?.status) {
      request.setStatus(params.status)
    }
    return new Promise((resolve, reject) => {
      client.listCustomers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getCustomer: (name) => {
    const request = new GetCustomerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  createCustomer: (customer) => {
    let cust = new Customer()
    cust.setFirstName(customer.firstName)
    cust.setLastName(customer.lastName)
    cust.setMiddleName(customer.middleName)
    cust.setCountryName(customer.countryName)
    cust.setCompanyDisplayName(customer.companyDisplayName)
    cust.setPhone(customer.phone)
    cust.setEmail(customer.email)
    cust.setType(customer.type)
    cust.setStatus(customer.status)
    cust.setExternalId(customer.externalId)

    if (customer.buyersList) {
      cust.setBuyersList(customer.buyersList)
    }
    
    const request = new CreateCustomerRequest()
    request.setCustomer(cust)

    const metadata = getMetadata()
    if (customer?.requestId) {
      metadata["X-RequestId"] = customer.requestId
    }
    return new Promise((resolve, reject) => {
      client.createCustomer(request, metadata, (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  updateCustomer: (customer) => {
    let cust = new Customer()
    cust.setFirstName(customer.firstName)
    cust.setLastName(customer.lastName)
    cust.setMiddleName(customer.middleName)
    cust.setCountryName(customer.countryName)
    cust.setCompanyDisplayName(customer.companyDisplayName)
    cust.setPhone(customer.phone)
    cust.setEmail(customer.email)
    cust.setType(customer.type)
    cust.setStatus(customer.status)
    cust.setExternalId(customer.externalId)

    if (customer.buyersList) {
      cust.setBuyersList(customer.buyersList)
    }

    const request = new UpdateCustomerRequest();
    request.setName(customer.name)
    request.setCustomer(cust)

    return new Promise((resolve, reject) => {
      client.updateCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  deleteCustomer: (name) => {
    const request = new DeleteCustomerRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
