// ** Icons Import
import { Clock, Navigation, Truck, Anchor, XCircle } from 'react-feather'

export default [
  {
    header: 'Cargoloop',
    resource: 'cargoloop',
    action: 'read'
  },
  {
    id: 'pending',
    title: 'Pending',
    icon: <Clock size={12} />,
    navLink: '/cargoloop/pending',
    resource: 'cargoloop',
    action: 'read'
  },
  {
    id: 'cancelled',
    title: 'Cancelled',
    icon: <XCircle size={12} />,
    navLink: '/cargoloop/cancelled',
    resource: 'cargoloop',
    action: 'read'
  },
  {
    id: 'vehicles',
    title: 'Vehicles',
    icon: <Truck size={12} />,
    navLink: '/cargoloop/vehicles',
    resource: 'cargoloop',
    action: 'read'
  },
  {
    id: 'local_transportations',
    title: 'Local Transportation',
    icon: <Navigation size={12} />,
    navLink: '/cargoloop/local_transportations',
    resource: 'cargoloop',
    action: 'read'
  },
  {
    id: 'containers',
    title: 'Containers',
    icon: <Anchor size={12} />,
    navLink: '/cargoloop/containers',
    resource: 'cargoloop',
    action: 'read'
  }
]
