// ** Icons Import
import { Calendar, Truck } from 'react-feather'

export default [
  {
    header: 'Orders',
    resource: 'orders',
    action: 'read'
  },
  /*{
    id: 'calendar',
    title: 'Calendar',
    icon: <Calendar size={20} />,
    navLink: '/calendar'
  },*/
  {
    id: 'orders',
    title: 'Orders',
    icon: <Truck size={20} />,
    navLink: '/orders',
    resource: 'orders',
    action: 'read'
  }
]
