// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/locations'

export const list = createAsyncThunk('appCountries/list', async () => {
  return await API.getCountries()
})

export const get = createAsyncThunk('appCountries/get', async name => {
  return await API.getCountry(name)
})

export const modify = createAsyncThunk('appCountries/modify', async (instance, { dispatch }) => {
  const updated = await API.updateCountry(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appCountriesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appCountriesSlice = createSlice({
  name: 'appCountries',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCountriesSlice.reducer
