// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/cargoloop'

export const list = createAsyncThunk('appLocalTransportations/list', async () => {
  return await API.getLocalTransportations()
})

export const appLocalTransportationsSlice = createSlice({
  name: 'appLocalTransportations',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appLocalTransportationsSlice.reducer
