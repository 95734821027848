import { ApiUrl, getMetadata } from '../index'
import { ListTerminalsRequest } from './terminals_pb'
import { TerminalsClient } from './terminals_grpc_web_pb'

let client = new TerminalsClient(ApiUrl, null, null);

const API = {
  getTerminals: () => {
    const request = new ListTerminalsRequest();
    return new Promise((resolve, reject) => {
      client.listTerminals(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().terminalsList)
        }
      });
    });
  }
};

export default API;
