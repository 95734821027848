import { ApiUrl, getMetadata } from '../index'
import { Invoice, InvoiceItem, InvoiceType, InvoicePayee } from './invoice_pb'
import {
  ListInvoicesRequest,
  GetInvoiceRequest,
  CreateInvoiceRequest,
  UpdateInvoiceRequest,
  DeleteInvoiceRequest,
  ListInvoiceTypesRequest,
  GetInvoiceTypeRequest,
  CreateInvoiceTypeRequest,
  UpdateInvoiceTypeRequest,
  DeleteInvoiceTypeRequest,
  ListInvoicePayeesRequest,
  GetInvoicePayeeRequest,
  CreateInvoicePayeeRequest,
  UpdateInvoicePayeeRequest,
  DeleteInvoicePayeeRequest
} from './invoices_pb'
import { Money } from '../money_pb'
import { Unit } from '../unit_pb'
import { InvoicesClient } from './invoices_grpc_web_pb'

// ** Utility
import { timestampToProto } from 'utility/utils'

let client = new InvoicesClient(ApiUrl, null, null);

const invoice2Model = (invoice) => {
  let inv = new Invoice()
  if (invoice.amount?.value) {
    let amt = new Money()
    amt.setValue(invoice.amount.value)
    amt.setCurrencyName(invoice.amount.currencyName)
    amt.setExponent(invoice.amount.exponent)
    inv.setAmount(amt)
  }
  inv.setNumber(invoice.number)
  inv.setDisplayName(invoice.displayName)
  inv.setCustomerName(invoice.customerName)
  inv.setInvoiceTypeName(invoice.invoiceTypeName)
  inv.setPayeeName(invoice.payeeName)
  inv.setStatus(invoice.status)
  inv.setComment(invoice.comment)
  if (invoice.date)
    inv.setDate(timestampToProto(invoice.date))
  if (invoice.dueDate)
    inv.setDueDate(timestampToProto(invoice.dueDate))
  inv.setCustomerDisplayNameActual(invoice.customerDisplayNameActual)
  inv.setCustomerDetails(invoice.customerDetails)

  let items = []
  if (invoice.itemsList) {
    invoice.itemsList.forEach((e) => {
      let item = new InvoiceItem()
      item.setId(e.id)
      item.setName(e.name)
      item.setDisplayName(e.displayName)
      item.setItemName(e.itemName)
      item.setOrderName(e.orderName)
      if (e.amount?.value) {
        let amt = new Money()
        amt.setValue(e.amount.value)
        amt.setCurrencyName(e.amount.currencyName)
        amt.setExponent(e.amount.exponent)
        item.setAmount(amt)
      }
      if (e.price?.value) {
        let amt = new Money()
        amt.setValue(e.price.value)
        amt.setCurrencyName(e.price.currencyName)
        amt.setExponent(e.price.exponent)
        item.setPrice(amt)
      }
      if (e.quantity?.value) {
        let qnt = new Unit()
        qnt.setValue(e.quantity.value)
        qnt.setUnitName(e.quantity.unitName)
        qnt.setExponent(e.quantity.exponent)
        item.setQuantity(qnt)
      }
      item.setComment(e.comment)
      items.push(item)
    })
  }

  inv.setItemsList(items)
 
  return inv
}

const invoiceType2Model = (instance) => {
  let result = new InvoiceType()
  result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setDocumentHeaderComment(instance.documentHeaderComment)
  result.setLogoUrl(instance.logoUrl)
  result.setIsActive(instance.isActive)
  return result
}

const invoicePayee2Model = (instance) => {
  let result = new InvoicePayee()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setBankDisplayName(instance.bankDisplayName)
  result.setBankPayeeDisplayName(instance.bankPayeeDisplayName)
  result.setBankPayeeAddressDisplayName(instance.bankPayeeAddressDisplayName)
  result.setCountryName(instance.countryCode)
  result.setSwift(instance.swift)
  result.setBankPayeeAccountDisplayName(instance.bankPayeeAccountDisplayName)
  result.setAdditionalRequisites(instance.additionalRequisites)
  result.setBankRoutingNumber(instance.bankRoutingNumber)
  result.setIsActive(instance.isActive)
  return result
}

const API = {
  listInvoices: (params) => {
    const request = new ListInvoicesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.customerName) {
      request.setCustomerName(params.customerName)
    }
    return new Promise((resolve, reject) => {
      client.listInvoices(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getCustomerInvoices: (customerName) => {
    const request = new ListInvoicesRequest()
    request.setCustomerName(customerName)
    return new Promise((resolve, reject) => {
      client.listInvoices(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().invoicesList)
        }
      })
    })
  },
  getOrderInvoices: (orderName) => {
    const request = new ListInvoicesRequest()
    request.setOrderName(orderName)
    return new Promise((resolve, reject) => {
      client.listInvoices(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().invoicesList)
        }
      })
    })
  },
  getInvoice: (name) => {
    const request = new GetInvoiceRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getInvoice(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  createInvoice: (invoice) => {
    const inv = invoice2Model(invoice)
    const request = new CreateInvoiceRequest()
    request.setInvoice(inv)

    const metadata = getMetadata()
    if (invoice?.requestId) {
      metadata["X-RequestId"] = invoice.requestId
    }
    return new Promise((resolve, reject) => {
      client.createInvoice(request, metadata, (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  updateInvoice: (invoice) => {
    const inv = invoice2Model(invoice)
    const request = new UpdateInvoiceRequest();
    request.setName(invoice.name)
    request.setInvoice(inv)

    return new Promise((resolve, reject) => {
      client.updateInvoice(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  deleteInvoice: (name) => {
    const request = new DeleteInvoiceRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteInvoice(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },

  listInvoiceTypes: () => {
    const request = new ListInvoiceTypesRequest()
    return new Promise((resolve, reject) => {
      client.listInvoiceTypes(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().invoiceTypesList)
        }
      })
    })
  },
  getInvoiceType: (name) => {
    const request = new GetInvoiceTypeRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getInvoiceType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  createInvoiceType: (invoiceType) => {
    const inv = invoiceType2Model(invoiceType)
    const request = new CreateInvoiceTypeRequest()
    request.setInvoiceType(inv)

    return new Promise((resolve, reject) => {
      client.createInvoiceType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  updateInvoiceType: (invoiceType) => {
    const inv = invoiceType2Model(invoiceType)
    const request = new UpdateInvoiceTypeRequest();
    request.setName(invoiceType.name)
    request.setInvoiceType(inv)

    return new Promise((resolve, reject) => {
      client.updateInvoiceType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteInvoiceType: (name) => {
    const request = new DeleteInvoiceTypeRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteInvoiceType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  listInvoicePayees: () => {
    const request = new ListInvoicePayeesRequest()
    return new Promise((resolve, reject) => {
      client.listInvoicePayees(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().invoicePayeesList)
        }
      })
    })
  },
  getInvoicePayee: (name) => {
    const request = new GetInvoicePayeeRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getInvoicePayee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  createInvoicePayee: (invoicePayee) => {
    const inv = invoicePayee2Model(invoicePayee)
    const request = new CreateInvoicePayeeRequest()
    request.setInvoicePayee(inv)

    return new Promise((resolve, reject) => {
      client.createInvoicePayee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  updateInvoicePayee: (invoicePayee) => {
    const inv = invoicePayee2Model(invoicePayee)
    const request = new UpdateInvoicePayeeRequest();
    request.setName(invoicePayee.name)
    request.setInvoicePayee(inv)

    return new Promise((resolve, reject) => {
      client.updateInvoicePayee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteInvoicePayee: (name) => {
    const request = new DeleteInvoicePayeeRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteInvoicePayee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
};

export default API;
