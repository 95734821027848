/**
 * @fileoverview gRPC-Web generated client stub for mycargo.projects.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/orders.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_order_pb = require('./order_pb.js')
const proto = {};
proto.mycargo = {};
proto.mycargo.projects = {};
proto.mycargo.projects.erp = {};
proto.mycargo.projects.erp.api = {};
proto.mycargo.projects.erp.api.v1 = {};
proto.mycargo.projects.erp.api.v1.services = require('./orders_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListOrdersRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListOrdersResponse>}
 */
const methodDescriptor_Orders_ListOrders = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/ListOrders',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListOrdersRequest,
  proto.mycargo.projects.erp.api.v1.services.ListOrdersResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListOrdersResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListOrdersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListOrdersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.listOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/ListOrders',
      request,
      metadata || {},
      methodDescriptor_Orders_ListOrders,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListOrdersResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.listOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/ListOrders',
      request,
      metadata || {},
      methodDescriptor_Orders_ListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetOrderRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Order>}
 */
const methodDescriptor_Orders_GetOrder = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/GetOrder',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetOrderRequest,
  projects_erp_service_api_v1_model_order_pb.Order,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.Order.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Order)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Order>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.getOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/GetOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_GetOrder,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Order>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.getOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/GetOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_GetOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.CreateOrderRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Order>}
 */
const methodDescriptor_Orders_CreateOrder = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/CreateOrder',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.CreateOrderRequest,
  projects_erp_service_api_v1_model_order_pb.Order,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.Order.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Order)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Order>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_CreateOrder,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Order>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateOrderRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Order>}
 */
const methodDescriptor_Orders_UpdateOrder = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrder',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateOrderRequest,
  projects_erp_service_api_v1_model_order_pb.Order,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.Order.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Order)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Order>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.updateOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_UpdateOrder,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Order>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.updateOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_UpdateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteOrderRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteOrderResponse>}
 */
const methodDescriptor_Orders_DeleteOrder = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrder',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderRequest,
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.DeleteOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.DeleteOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.deleteOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_DeleteOrder,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.DeleteOrderResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.deleteOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrder',
      request,
      metadata || {},
      methodDescriptor_Orders_DeleteOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesResponse>}
 */
const methodDescriptor_Orders_ListOrderServiceTypes = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/ListOrderServiceTypes',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesRequest,
  proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.listOrderServiceTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/ListOrderServiceTypes',
      request,
      metadata || {},
      methodDescriptor_Orders_ListOrderServiceTypes,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListOrderServiceTypesResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.listOrderServiceTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/ListOrderServiceTypes',
      request,
      metadata || {},
      methodDescriptor_Orders_ListOrderServiceTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetOrderServiceTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.OrderServiceType>}
 */
const methodDescriptor_Orders_GetOrderServiceType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/GetOrderServiceType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetOrderServiceTypeRequest,
  projects_erp_service_api_v1_model_order_pb.OrderServiceType,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderServiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.OrderServiceType.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.OrderServiceType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.OrderServiceType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.getOrderServiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/GetOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_GetOrderServiceType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.OrderServiceType>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.getOrderServiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/GetOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_GetOrderServiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.CreateOrderServiceTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.OrderServiceType>}
 */
const methodDescriptor_Orders_CreateOrderServiceType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/CreateOrderServiceType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.CreateOrderServiceTypeRequest,
  projects_erp_service_api_v1_model_order_pb.OrderServiceType,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderServiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.OrderServiceType.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.OrderServiceType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.OrderServiceType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.createOrderServiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/CreateOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_CreateOrderServiceType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.OrderServiceType>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.createOrderServiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/CreateOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_CreateOrderServiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateOrderServiceTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.OrderServiceType>}
 */
const methodDescriptor_Orders_UpdateOrderServiceType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrderServiceType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateOrderServiceTypeRequest,
  projects_erp_service_api_v1_model_order_pb.OrderServiceType,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderServiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.OrderServiceType.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.OrderServiceType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.OrderServiceType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.updateOrderServiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_UpdateOrderServiceType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.OrderServiceType>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.updateOrderServiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_UpdateOrderServiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeResponse>}
 */
const methodDescriptor_Orders_DeleteOrderServiceType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrderServiceType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeRequest,
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.deleteOrderServiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_DeleteOrderServiceType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.DeleteOrderServiceTypeResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.deleteOrderServiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrderServiceType',
      request,
      metadata || {},
      methodDescriptor_Orders_DeleteOrderServiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesResponse>}
 */
const methodDescriptor_Orders_ListOrderDiscountTypes = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/ListOrderDiscountTypes',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesRequest,
  proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.listOrderDiscountTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/ListOrderDiscountTypes',
      request,
      metadata || {},
      methodDescriptor_Orders_ListOrderDiscountTypes,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListOrderDiscountTypesResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.listOrderDiscountTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/ListOrderDiscountTypes',
      request,
      metadata || {},
      methodDescriptor_Orders_ListOrderDiscountTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetOrderDiscountTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>}
 */
const methodDescriptor_Orders_GetOrderDiscountType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/GetOrderDiscountType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetOrderDiscountTypeRequest,
  projects_erp_service_api_v1_model_order_pb.OrderDiscountType,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderDiscountTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.OrderDiscountType.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.OrderDiscountType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.getOrderDiscountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/GetOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_GetOrderDiscountType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.getOrderDiscountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/GetOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_GetOrderDiscountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.CreateOrderDiscountTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>}
 */
const methodDescriptor_Orders_CreateOrderDiscountType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/CreateOrderDiscountType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.CreateOrderDiscountTypeRequest,
  projects_erp_service_api_v1_model_order_pb.OrderDiscountType,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderDiscountTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.OrderDiscountType.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.OrderDiscountType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.createOrderDiscountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/CreateOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_CreateOrderDiscountType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.createOrderDiscountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/CreateOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_CreateOrderDiscountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateOrderDiscountTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>}
 */
const methodDescriptor_Orders_UpdateOrderDiscountType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrderDiscountType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateOrderDiscountTypeRequest,
  projects_erp_service_api_v1_model_order_pb.OrderDiscountType,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderDiscountTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_order_pb.OrderDiscountType.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.OrderDiscountType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.updateOrderDiscountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_UpdateOrderDiscountType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.updateOrderDiscountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/UpdateOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_UpdateOrderDiscountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeResponse>}
 */
const methodDescriptor_Orders_DeleteOrderDiscountType = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrderDiscountType',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeRequest,
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.OrdersClient.prototype.deleteOrderDiscountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_DeleteOrderDiscountType,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.DeleteOrderDiscountTypeResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.OrdersPromiseClient.prototype.deleteOrderDiscountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Orders/DeleteOrderDiscountType',
      request,
      metadata || {},
      methodDescriptor_Orders_DeleteOrderDiscountType);
};


module.exports = proto.mycargo.projects.erp.api.v1.services;

