// ** Icons Import
import {FileText, DollarSign, Users} from 'react-feather'

export default [
  {
    header: 'Finance',
    resource: 'finance',
    action: 'read'
  },
  {
    id: 'invoices',
    title: 'Invoices',
    icon: <FileText size={12} />,
    navLink: '/invoices',
    resource: 'invoices',
    action: 'read'
  },
  {
    id: 'payments',
    title: 'Payments',
    icon: <DollarSign size={12} />,
    navLink: '/payments',
    resource: 'payments',
    action: 'read'
  },
  {
    id: 'beneficiaries',
    title: 'Beneficiaries',
    icon: <Users size={12} />,
    navLink: '/beneficiaries',
    resource: 'invoice_payees',
    action: 'read'
  }
]
