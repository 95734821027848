import { ApiUrl, getMetadata } from '../index'
import { Formula } from './formula_pb'
import {
  ListFormulasRequest,
  CreateFormulaRequest,
  GetFormulaRequest,
  UpdateFormulaRequest,
  DeleteFormulaRequest
} from './formulas_pb'
import { FormulasClient } from './formulas_grpc_web_pb'

let client = new FormulasClient(ApiUrl, null, null);

const formula2Model = (formula) => {
  let fml = new Formula()
  fml.setEntityName(formula.entityName ?? '')
  fml.setKey(formula.key ?? '')
  fml.setValue(formula.value ?? '')
  for (const i in formula.filtersMap) {
    fml.getFiltersMap().set(formula.filtersMap[i][0], formula.filtersMap[i][1])
  }

  return fml
}

const API = {
  listFormulas: (params) => {
    const request = new ListFormulasRequest()
    if (params?.entityName) {
      request.setEntityName(params.entityName)
    }
    return new Promise((resolve, reject) => {
      client.listFormulas(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().formulasList)
        }
      })
    })
  },
  getFormula: (name) => {
    const request = new GetFormulaRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getFormula(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  createFormula: (formula) => {
    const fml = formula2Model(formula)
    const request = new CreateFormulaRequest()
    request.setFormula(fml)

    return new Promise((resolve, reject) => {
      client.createFormula(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  updateFormula: (formula) => {
    const fml = formula2Model(formula)
    const request = new UpdateFormulaRequest()
    request.setName(formula.name)
    request.setFormula(fml)

    return new Promise((resolve, reject) => {
      client.updateFormula(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteFormula: (name) => {
    const request = new DeleteFormulaRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteFormula(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
}

export default API
