// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import countries from 'views/countries/store'
import states from 'views/states/store'
import cities from 'views/cities/store'
import terminals from 'views/locations/terminals/store'
import customers from 'views/users/customers/store'
import suspended from 'views/users/suspended/store'
import vehicles from 'views/cargoloop/vehicles/store'
import orders from 'views/ordering/orders/store'
import invoices from 'views/financial/invoices/store'
import payments from 'views/financial/payments/store'
import orderServiceTypes from 'views/references/service-types/store'
import orderDiscountTypes from 'views/references/discount-types/store'
import invoiceTypes from 'views/references/invoice-types/store'
import invoicePayees from 'views/financial/invoice-payees/store'
import paymentTypes from 'views/references/payment-types/store'
import formulas from 'views/references/formulas/store'
import buyers from 'views/cargoloop/buyers/store'
import localTransportations from 'views/cargoloop/local-transportations/store'
import containers from 'views/cargoloop/containers/store'

const rootReducer = {
  auth,
  countries,
  states,
  cities,
  terminals,
  customers,
  suspended,
  vehicles,
  orders,
  invoices,
  payments,
  orderServiceTypes,
  orderDiscountTypes,
  invoiceTypes,
  invoicePayees,
  paymentTypes,
  formulas,
  buyers,
  localTransportations,
  containers,

  navbar,
  layout
}

export default rootReducer
