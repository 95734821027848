// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/payments'

export const list = createAsyncThunk('appPaymentTypes/list', async () => {
	return await API.listPaymentTypes()
})

export const get = createAsyncThunk('appPaymentTypes/get', async name => {
	return await API.getPaymentType(name)
})

export const add = createAsyncThunk(
	'appPaymentTypes/add',
	async (instance, { dispatch }) => {
		const updated = await API.createPaymentType(instance)
		instance = {
			...instance,
			updated,
		}
		await dispatch(list())
		return instance
	}
)

export const modify = createAsyncThunk(
	'appPaymentTypes/modify',
	async (instance, { dispatch }) => {
		const updated = await API.updatePaymentType(instance)
		instance = {
			...instance,
			updated,
		}
		await dispatch(appPaymentTypesSlice.actions.setSelected(instance))
		await dispatch(list())
	}
)

export const del = createAsyncThunk(
	'appPaymentTypes/del',
	async (name, { dispatch }) => {
		await API.deletePaymentType(name)
		await dispatch(list())
		return name
	}
)

export const appPaymentTypesSlice = createSlice({
	name: 'appPaymentTypes',
	initialState: {
		data: [],
		selected: null,
		DistributePaid: {},
	},
	reducers: {
		setSelected: (state, { payload }) => {
			state.selected = payload
		},
		setDistributePaid: (state, { payload }) => {
			state.DistributePaid = { ...state.DistributePaid, payload }
		},
	},
	extraReducers: builder => {
		builder
			.addCase(list.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(get.fulfilled, (state, { payload }) => {
				state.selected = payload
			})
	},
})
export const { setDistributePaid } = appPaymentTypesSlice.actions
export default appPaymentTypesSlice.reducer
