// ** React Imports
import { lazy } from 'react'

const VehicleList = lazy(() => import('views/cargoloop/vehicles/list'))
const VehicleView = lazy(() => import('views/cargoloop/vehicles/view'))
const VehicleOrdering = lazy(() => import('views/cargoloop/vehicles/ordering'))
const LocalTransportationsList = lazy(() => import('views/cargoloop/local-transportations/list'))
const ContainerList = lazy(() => import('views/cargoloop/containers/list'))
const ContainerView = lazy(() => import('views/cargoloop/containers/view'))

const CargoloopRoutes = [
  {
    element: <VehicleList />,
    path: '/cargoloop/pending',
    meta: {
      resource: 'cargoloop',
      action: 'read'
    }
  },
  {
    element: <VehicleList />,
    path: '/cargoloop/cancelled',
    meta: {
      resource: 'cargoloop',
      action: 'read'
    }
  },
  {
    element: <VehicleOrdering />,
    path: '/cargoloop/vehicles/:id/orders/-/add',
    meta: {
      className: 'ecommerce-application',
      resource: 'orders',
      action: 'add'
    }
  },
  {
    element: <VehicleView />,
    path: '/cargoloop/vehicles/:id',
    meta: {
      className: 'ecommerce-application',
      resource: 'cargoloop',
      action: 'read'
    }
  },
  {
    element: <VehicleList />,
    path: '/cargoloop/vehicles',
    meta: {
      resource: 'cargoloop',
      action: 'read'
    }
  },
  {
    element: <LocalTransportationsList />,
    path: '/cargoloop/local_transportations',
    meta: {
      resource: 'cargoloop',
      action: 'read'
    }
  },
  {
    element: <ContainerView />,
    path: '/cargoloop/containers/:id',
    meta: {
      className: 'ecommerce-application',
      resource: 'cargoloop',
      action: 'read'
    }
  },
  {
    element: <ContainerList />,
    path: '/cargoloop/containers',
    meta: {
      resource: 'cargoloop',
      action: 'read'
    }
  }
]

export default CargoloopRoutes
