// source: projects/erp/service/api/v1/model/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.Customer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.Customer.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.Customer.displayName = 'proto.mycargo.projects.erp.api.v1.model.Customer';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.Customer.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    middleName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    companyDisplayName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    email: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    orderCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    activeOrderCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    balance: (f = msg.getBalance()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 16, ""),
    paymentDueDate: (f = msg.getPaymentDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    externalId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    pendingBalance: (f = msg.getPendingBalance()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    buyersList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.Customer;
  return proto.mycargo.projects.erp.api.v1.model.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDisplayName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActiveOrderCount(value);
      break;
    case 15:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentDueDate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 19:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setPendingBalance(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addBuyers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMiddleName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCompanyDisplayName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrderCount();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getActiveOrderCount();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPaymentDueDate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPendingBalance();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getBuyersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string middle_name = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_name = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string company_display_name = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getCompanyDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setCompanyDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone = 11;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 order_count = 13;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 active_order_count = 14;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getActiveOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setActiveOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Money balance = 15;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getBalance = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 15));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string payment_status = 16;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp payment_due_date = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPaymentDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPaymentDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearPaymentDueDate = function() {
  return this.setPaymentDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasPaymentDueDate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string external_id = 18;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional Money pending_balance = 19;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPendingBalance = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 19));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPendingBalance = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearPendingBalance = function() {
  return this.setPendingBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasPendingBalance = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated string buyers = 20;
 * @return {!Array<string>}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getBuyersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setBuyersList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.addBuyers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearBuyersList = function() {
  return this.setBuyersList([]);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.model);
