// source: projects/erp/service/api/v1/services/shipments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var projects_erp_service_api_v1_model_shipment_pb = require('./shipment_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_shipment_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 4, ""),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest;
  return proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortColumn(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSortColumn();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sort_column = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.getSortColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    projects_erp_service_api_v1_model_shipment_pb.Shipment.toObject, includeInstance),
    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse;
  return proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_shipment_pb.Shipment;
      reader.readMessage(value,projects_erp_service_api_v1_model_shipment_pb.Shipment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRows(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_shipment_pb.Shipment.serializeBinaryToWriter
    );
  }
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated mycargo.projects.erp.api.v1.model.Shipment shipments = 1;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.Shipment>}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.Shipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_shipment_pb.Shipment, 1));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.Shipment>} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} returns this
*/
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.Shipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mycargo.projects.erp.api.v1.model.Shipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};


/**
 * optional int64 total_rows = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_pages = 3;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListShipmentsResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest}
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest;
  return proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest}
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.GetShipmentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.services);
