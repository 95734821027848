// ** Third Party Components
import {useTranslation} from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

// ** Reactstrap Imports
import {UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap'

const IntlDropdown = () => {
    // ** Hooks
    const {i18n} = useTranslation()

    // ** Vars
    const langObj = {
        en: 'English',
        ru: 'Русский',
        am: 'հայերեն'
    }

    // ** Function to switch Language
    const handleLangUpdate = (e, lang) => {
        e.preventDefault()
        i18n.changeLanguage(lang)
    }

    const langIcon = (lang) => lang === "en" ? "us" : lang;

    return (
        <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
            <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
                <ReactCountryFlag
                    svg
                    className='country-flag flag-icon'
                    countryCode={langIcon(i18n.language)}
                />
                <span className='selected-language'>{langObj[i18n.language]}</span>
            </DropdownToggle>
            <DropdownMenu className='mt-0' end>
                {
                    Object
                        .entries(langObj)
                        .map(([lang, langName]) => (
                            <DropdownItem key={langName} href='/' tag='a' onClick={e => handleLangUpdate(e, lang)}>
                                <ReactCountryFlag className='country-flag' countryCode={langIcon(lang)} svg/>
                                <span className='ms-1'>{langName}</span>
                            </DropdownItem>
                        ))
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default IntlDropdown
