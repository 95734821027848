// source: projects/erp/service/api/v1/model/order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
var projects_erp_service_api_v1_model_vehicle_pb = require('../vehicles/vehicle_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_vehicle_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.Order', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.OrderDiscount', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.OrderDiscountType', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.OrderItem', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.OrderService', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.OrderServiceType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.Order.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.Order.displayName = 'proto.mycargo.projects.erp.api.v1.model.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.OrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.OrderItem.displayName = 'proto.mycargo.projects.erp.api.v1.model.OrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.OrderService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.OrderService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.OrderService.displayName = 'proto.mycargo.projects.erp.api.v1.model.OrderService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.OrderServiceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.OrderServiceType.displayName = 'proto.mycargo.projects.erp.api.v1.model.OrderServiceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.OrderDiscount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.OrderDiscount.displayName = 'proto.mycargo.projects.erp.api.v1.model.OrderDiscount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.OrderDiscountType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.displayName = 'proto.mycargo.projects.erp.api.v1.model.OrderDiscountType';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.Order.repeatedFields_ = [9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mycargo.projects.erp.api.v1.model.Order.prototype.toObject = function(opt_includeInstance) {
    return proto.mycargo.projects.erp.api.v1.model.Order.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mycargo.projects.erp.api.v1.model.Order} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mycargo.projects.erp.api.v1.model.Order.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      id: jspb.Message.getFieldWithDefault(msg, 2, 0),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      customerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
      paidAmount: (f = msg.getPaidAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
      status: jspb.Message.getFieldWithDefault(msg, 7, ""),
      comment: jspb.Message.getFieldWithDefault(msg, 8, ""),
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
          proto.mycargo.projects.erp.api.v1.model.OrderItem.toObject, includeInstance),
      servicesList: jspb.Message.toObjectList(msg.getServicesList(),
          proto.mycargo.projects.erp.api.v1.model.OrderService.toObject, includeInstance),
      discountsList: jspb.Message.toObjectList(msg.getDiscountsList(),
          proto.mycargo.projects.erp.api.v1.model.OrderDiscount.toObject, includeInstance),
      revertedAmount: (f = msg.getRevertedAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order}
 */
proto.mycargo.projects.erp.api.v1.model.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.Order;
  return proto.mycargo.projects.erp.api.v1.model.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order}
 */
proto.mycargo.projects.erp.api.v1.model.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCustomerName(value);
        break;
      case 5:
        var value = new projects_erp_service_api_v1_model_money_pb.Money;
        reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
        msg.setAmount(value);
        break;
      case 6:
        var value = new projects_erp_service_api_v1_model_money_pb.Money;
        reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
        msg.setPaidAmount(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      case 9:
        var value = new proto.mycargo.projects.erp.api.v1.model.OrderItem;
        reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.OrderItem.deserializeBinaryFromReader);
        msg.addItems(value);
        break;
      case 10:
        var value = new proto.mycargo.projects.erp.api.v1.model.OrderService;
        reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.OrderService.deserializeBinaryFromReader);
        msg.addServices(value);
        break;
      case 11:
        var value = new proto.mycargo.projects.erp.api.v1.model.OrderDiscount;
        reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.OrderDiscount.deserializeBinaryFromReader);
        msg.addDiscounts(value);
        break;
      case 12:
        var value = new projects_erp_service_api_v1_model_money_pb.Money;
        reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
        msg.setRevertedAmount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
        2,
        f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
        5,
        f,
        projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getPaidAmount();
  if (f != null) {
    writer.writeMessage(
        6,
        f,
        projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
        7,
        f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        9,
        f,
        proto.mycargo.projects.erp.api.v1.model.OrderItem.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        10,
        f,
        proto.mycargo.projects.erp.api.v1.model.OrderService.serializeBinaryToWriter
    );
  }
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        11,
        f,
        proto.mycargo.projects.erp.api.v1.model.OrderDiscount.serializeBinaryToWriter
    );
  }
  f = message.getRevertedAmount();
  if (f != null) {
    writer.writeMessage(
        12,
        f,
        projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Money amount = 5;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 5));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Money paid_amount = 6;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getPaidAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 6));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setPaidAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.clearPaidAmount = function() {
  return this.setPaidAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.hasPaidAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string comment = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated OrderItem items = 9;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.OrderItem>}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.OrderItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.OrderItem, 9));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.OrderItem>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.mycargo.projects.erp.api.v1.model.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated OrderService services = 10;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.OrderService>}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.OrderService>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.OrderService, 10));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.OrderService>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.mycargo.projects.erp.api.v1.model.OrderService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated OrderDiscount discounts = 11;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.OrderDiscount>}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getDiscountsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.OrderDiscount>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.OrderDiscount, 11));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.OrderDiscount>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.addDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.mycargo.projects.erp.api.v1.model.OrderDiscount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};

/**
 * optional Money reverted_amount = 12;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.getRevertedAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 12));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.setRevertedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Order} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.clearRevertedAmount = function() {
  return this.setRevertedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Order.prototype.hasRevertedAmount = function() {
  return jspb.Message.getField(this, 12);
};



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.toObject = function(opt_includeInstance) {
    return proto.mycargo.projects.erp.api.v1.model.OrderItem.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mycargo.projects.erp.api.v1.model.OrderItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mycargo.projects.erp.api.v1.model.OrderItem.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      id: jspb.Message.getFieldWithDefault(msg, 2, 0),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      itemName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
      comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
      vehicle: (f = msg.getVehicle()) && projects_erp_service_api_v1_model_vehicle_pb.Vehicle.toObject(includeInstance, f),
      valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.OrderItem;
  return proto.mycargo.projects.erp.api.v1.model.OrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setItemName(value);
        break;
      case 5:
        var value = new projects_erp_service_api_v1_model_money_pb.Money;
        reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
        msg.setAmount(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      case 7:
        var value = new projects_erp_service_api_v1_model_vehicle_pb.Vehicle;
        reader.readMessage(value,projects_erp_service_api_v1_model_vehicle_pb.Vehicle.deserializeBinaryFromReader);
        msg.setVehicle(value);
        break;
      case 8:
        var value = msg.getValuesMap();
        reader.readMessage(value, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.OrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
        2,
        f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getItemName();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
        5,
        f,
        projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
        7,
        f,
        projects_erp_service_api_v1_model_vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string item_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Money amount = 5;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 5));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Vehicle vehicle = 7;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Vehicle}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getVehicle = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Vehicle} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_vehicle_pb.Vehicle, 7));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Vehicle|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * map<string, string> values = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
          null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderItem.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.toObject = function(opt_includeInstance) {
    return proto.mycargo.projects.erp.api.v1.model.OrderService.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mycargo.projects.erp.api.v1.model.OrderService} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mycargo.projects.erp.api.v1.model.OrderService.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      id: jspb.Message.getFieldWithDefault(msg, 2, 0),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      serviceTypeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
      comment: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.OrderService;
  return proto.mycargo.projects.erp.api.v1.model.OrderService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setServiceTypeName(value);
        break;
      case 5:
        var value = new projects_erp_service_api_v1_model_money_pb.Money;
        reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
        msg.setAmount(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.OrderService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
        2,
        f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getServiceTypeName();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
        5,
        f,
        projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_type_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.getServiceTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.setServiceTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Money amount = 5;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 5));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderService} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderService.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.toObject = function(opt_includeInstance) {
    return proto.mycargo.projects.erp.api.v1.model.OrderServiceType.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mycargo.projects.erp.api.v1.model.OrderServiceType.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      code: jspb.Message.getFieldWithDefault(msg, 2, ""),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      isActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      orderIndex: jspb.Message.getFieldWithDefault(msg, 7, 0),
      groupDisplayName: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.OrderServiceType;
  return proto.mycargo.projects.erp.api.v1.model.OrderServiceType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsActive(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsRequired(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsDefault(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setOrderIndex(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupDisplayName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.OrderServiceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
        4,
        f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
        5,
        f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
        6,
        f
    );
  }
  f = message.getOrderIndex();
  if (f !== 0) {
    writer.writeInt32(
        7,
        f
    );
  }
  f = message.getGroupDisplayName();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_active = 4;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_required = 5;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_default = 6;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 order_index = 7;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getOrderIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setOrderIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string group_display_name = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.getGroupDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderServiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderServiceType.prototype.setGroupDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.toObject = function(opt_includeInstance) {
    return proto.mycargo.projects.erp.api.v1.model.OrderDiscount.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mycargo.projects.erp.api.v1.model.OrderDiscount.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      id: jspb.Message.getFieldWithDefault(msg, 2, 0),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      discountTypeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      serviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      sourceName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
      comment: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.OrderDiscount;
  return proto.mycargo.projects.erp.api.v1.model.OrderDiscount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDiscountTypeName(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setServiceName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setSourceName(value);
        break;
      case 7:
        var value = new projects_erp_service_api_v1_model_money_pb.Money;
        reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
        msg.setAmount(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.OrderDiscount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
        2,
        f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getDiscountTypeName();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getSourceName();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
        7,
        f,
        projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string discount_type_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getDiscountTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setDiscountTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string service_name = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string source_name = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getSourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setSourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Money amount = 7;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
      jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 7));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string comment = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscount} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscount.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.toObject = function(opt_includeInstance) {
    return proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      code: jspb.Message.getFieldWithDefault(msg, 2, ""),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      isActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.OrderDiscountType;
  return proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsActive(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
        4,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_active = 4;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.OrderDiscountType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.OrderDiscountType.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.model);
