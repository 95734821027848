// source: projects/erp/service/api/v1/model/vehicle.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.ListOfStrings', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.Vehicle', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleCustomer', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleInvoice', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleOrder', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.Vehicle.displayName = 'proto.mycargo.projects.erp.api.v1.model.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.ListOfStrings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.ListOfStrings.displayName = 'proto.mycargo.projects.erp.api.v1.model.ListOfStrings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleInvoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleInvoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleOrder.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.VehicleCustomer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.displayName = 'proto.mycargo.projects.erp.api.v1.model.VehicleCustomer';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    details: (f = msg.getDetails()) && proto.mycargo.projects.erp.api.v1.model.VehicleDetails.toObject(includeInstance, f),
    log: (f = msg.getLog()) && proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.toObject(includeInstance, f),
    vin: (f = msg.getVin()) && proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.toObject(includeInstance, f),
    shipment: (f = msg.getShipment()) && proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.toObject(includeInstance, f),
    container: (f = msg.getContainer()) && proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.toObject(includeInstance, f),
    client: (f = msg.getClient()) && proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.toObject(includeInstance, f),
    consignee: (f = msg.getConsignee()) && proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.toObject(includeInstance, f),
    purchase: (f = msg.getPurchase()) && proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.toObject(includeInstance, f),
    dispatch: (f = msg.getDispatch()) && proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.toObject(includeInstance, f),
    instructions: (f = msg.getInstructions()) && proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.toObject(includeInstance, f),
    fees: (f = msg.getFees()) && proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.toObject(includeInstance, f),
    documentsMap: (f = msg.getDocumentsMap()) ? f.toObject(includeInstance, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.toObject) : [],
    photosMap: (f = msg.getPhotosMap()) ? f.toObject(includeInstance, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.toObject) : [],
    emailNotificationsList: jspb.Message.toObjectList(msg.getEmailNotificationsList(),
    proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.toObject, includeInstance),
    isArchive: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    order: (f = msg.getOrder()) && proto.mycargo.projects.erp.api.v1.model.VehicleOrder.toObject(includeInstance, f),
    localTransportationPrice: (f = msg.getLocalTransportationPrice()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.Vehicle;
  return proto.mycargo.projects.erp.api.v1.model.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 4:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.deserializeBinaryFromReader);
      msg.setLog(value);
      break;
    case 5:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.deserializeBinaryFromReader);
      msg.setVin(value);
      break;
    case 6:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    case 7:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.deserializeBinaryFromReader);
      msg.setContainer(value);
      break;
    case 8:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 9:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.deserializeBinaryFromReader);
      msg.setConsignee(value);
      break;
    case 10:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.deserializeBinaryFromReader);
      msg.setPurchase(value);
      break;
    case 11:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.deserializeBinaryFromReader);
      msg.setDispatch(value);
      break;
    case 12:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.deserializeBinaryFromReader);
      msg.setInstructions(value);
      break;
    case 13:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.deserializeBinaryFromReader);
      msg.setFees(value);
      break;
    case 14:
      var value = msg.getDocumentsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.mycargo.projects.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 15:
      var value = msg.getPhotosMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.mycargo.projects.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 16:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.deserializeBinaryFromReader);
      msg.addEmailNotifications(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArchive(value);
      break;
    case 18:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleOrder;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 19:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setLocalTransportationPrice(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleDetails.serializeBinaryToWriter
    );
  }
  f = message.getLog();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.serializeBinaryToWriter
    );
  }
  f = message.getVin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.serializeBinaryToWriter
    );
  }
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.serializeBinaryToWriter
    );
  }
  f = message.getContainer();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.serializeBinaryToWriter
    );
  }
  f = message.getConsignee();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.serializeBinaryToWriter
    );
  }
  f = message.getPurchase();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.serializeBinaryToWriter
    );
  }
  f = message.getDispatch();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.serializeBinaryToWriter
    );
  }
  f = message.getInstructions();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.serializeBinaryToWriter
    );
  }
  f = message.getFees();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.serializeBinaryToWriter
    );
  }
  f = message.getDocumentsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(14, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getPhotosMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getEmailNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.serializeBinaryToWriter
    );
  }
  f = message.getIsArchive();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleOrder.serializeBinaryToWriter
    );
  }
  f = message.getLocalTransportationPrice();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getCancelled();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional VehicleDetails details = 3;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getDetails = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleDetails, 3));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional VehicleLogDetails log = 4;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getLog = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails, 4));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setLog = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearLog = function() {
  return this.setLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasLog = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional VehicleVinDetails vin = 5;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getVin = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails, 5));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearVin = function() {
  return this.setVin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasVin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional VehicleShipmentDetails shipment = 6;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getShipment = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails, 6));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VehicleContainerDetails container = 7;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getContainer = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails, 7));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setContainer = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearContainer = function() {
  return this.setContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasContainer = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional VehicleClientDetails client = 8;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getClient = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails, 8));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasClient = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional VehicleConsigneeDetails consignee = 9;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getConsignee = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails, 9));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setConsignee = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearConsignee = function() {
  return this.setConsignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasConsignee = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional VehiclePurchaseDetails purchase = 10;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getPurchase = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails, 10));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setPurchase = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearPurchase = function() {
  return this.setPurchase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasPurchase = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional VehicleDispatchDetails dispatch = 11;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getDispatch = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails, 11));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setDispatch = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearDispatch = function() {
  return this.setDispatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasDispatch = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional VehicleInstructionsDetails instructions = 12;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getInstructions = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails, 12));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearInstructions = function() {
  return this.setInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasInstructions = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional VehicleFeesDetails fees = 13;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getFees = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails, 13));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setFees = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearFees = function() {
  return this.setFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasFees = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * map<string, ListOfStrings> documents = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.mycargo.projects.erp.api.v1.model.ListOfStrings>}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getDocumentsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.mycargo.projects.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 14, opt_noLazyCreate,
      proto.mycargo.projects.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearDocumentsMap = function() {
  this.getDocumentsMap().clear();
  return this;};


/**
 * map<string, ListOfStrings> photos = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.mycargo.projects.erp.api.v1.model.ListOfStrings>}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getPhotosMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.mycargo.projects.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      proto.mycargo.projects.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearPhotosMap = function() {
  this.getPhotosMap().clear();
  return this;};


/**
 * repeated VehicleEmailNotification email_notifications = 16;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification>}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getEmailNotificationsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification, 16));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setEmailNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.addEmailNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearEmailNotificationsList = function() {
  return this.setEmailNotificationsList([]);
};


/**
 * optional bool is_archive = 17;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getIsArchive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setIsArchive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional VehicleOrder order = 18;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleOrder}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getOrder = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleOrder} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleOrder, 18));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleOrder|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Money local_transportation_price = 19;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getLocalTransportationPrice = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 19));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setLocalTransportationPrice = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.clearLocalTransportationPrice = function() {
  return this.setLocalTransportationPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.hasLocalTransportationPrice = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool cancelled = 20;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.getCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Vehicle.prototype.setCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.ListOfStrings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings}
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.ListOfStrings;
  return proto.mycargo.projects.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings}
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addStrings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStringsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string strings = 1;
 * @return {!Array<string>}
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.prototype.getStringsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.prototype.setStringsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.prototype.addStrings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ListOfStrings} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ListOfStrings.prototype.clearStringsList = function() {
  return this.setStringsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    auction: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auctionLocation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    client: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subClient: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buyer: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vehicleType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lotNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionLocation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubClient(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLotNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuctionLocation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubClient();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuyer();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVehicleType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLotNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string auction = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getAuction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setAuction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auction_location = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getAuctionLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setAuctionLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub_client = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getSubClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setSubClient = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string buyer = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getBuyer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setBuyer = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string vehicle_type = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getVehicleType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lot_number = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getLotNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setLotNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vin = 9;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDetails.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateEntered: (f = msg.getDateEntered()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enteredBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.toObject, includeInstance),
    keys: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateEntered(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnteredBy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleInvoice;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateEntered();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnteredBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.serializeBinaryToWriter
    );
  }
  f = message.getKeys();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date_entered = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.getDateEntered = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.setDateEntered = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.clearDateEntered = function() {
  return this.setDateEntered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.hasDateEntered = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string entered_by = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.getEnteredBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.setEnteredBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated VehicleInvoice invoices = 4;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice>}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleInvoice, 4));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mycargo.projects.erp.api.v1.model.VehicleInvoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};


/**
 * optional string keys = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.getKeys = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleLogDetails.prototype.setKeys = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invoicedBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    invoiceDate: (f = msg.getInvoiceDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sentDate: (f = msg.getSentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sentTo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    aging: jspb.Message.getFieldWithDefault(msg, 8, 0),
    amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    openBalance: (f = msg.getOpenBalance()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleInvoice;
  return proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoicedBy(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvoiceDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSentDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentTo(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAging(value);
      break;
    case 9:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 10:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setOpenBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvoicedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvoiceDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSentDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSentTo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAging();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getOpenBalance();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string invoice_number = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string invoiced_by = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getInvoicedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setInvoicedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp invoice_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getInvoiceDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setInvoiceDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.clearInvoiceDate = function() {
  return this.setInvoiceDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.hasInvoiceDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp sent_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getSentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setSentDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.clearSentDate = function() {
  return this.setSentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.hasSentDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string sent_to = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getSentTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setSentTo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 aging = 8;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getAging = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setAging = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Money amount = 9;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 9));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Money open_balance = 10;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.getOpenBalance = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 10));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.setOpenBalance = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInvoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.clearOpenBalance = function() {
  return this.setOpenBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInvoice.prototype.hasOpenBalance = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    engine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    engineCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    useType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bodyTrim: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pb_class: jspb.Message.getFieldWithDefault(msg, 6, ""),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assyPlant: jspb.Message.getFieldWithDefault(msg, 8, ""),
    curbWeight: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngineCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBodyTrim(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClass(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssyPlant(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurbWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEngine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEngineCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUseType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBodyTrim();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClass();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssyPlant();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCurbWeight();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string engine = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getEngine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setEngine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string engine_code = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getEngineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setEngineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string use_type = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getUseType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setUseType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string body_trim = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getBodyTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setBodyTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string class = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setClass = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string manufacturer = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string assy_plant = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getAssyPlant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setAssyPlant = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 curb_weight = 9;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.getCurbWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleVinDetails.prototype.setCurbWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    portOfDischarge: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeOfDelivery: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortOfDischarge(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceOfDelivery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortOfDischarge();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceOfDelivery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string port_of_discharge = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.prototype.getPortOfDischarge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.prototype.setPortOfDischarge = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_of_delivery = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.prototype.getPlaceOfDelivery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleShipmentDetails.prototype.setPlaceOfDelivery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bookingNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shippedDate: (f = msg.getShippedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    arrivalDate: (f = msg.getArrivalDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    shippingLine: jspb.Message.getFieldWithDefault(msg, 5, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingNumber(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setShippedDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setArrivalDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShippingLine(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBookingNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShippedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArrivalDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getShippingLine();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_number = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.getBookingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.setBookingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp shipped_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.getShippedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.setShippedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.clearShippedDate = function() {
  return this.setShippedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.hasShippedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp arrival_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.getArrivalDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.setArrivalDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.clearArrivalDate = function() {
  return this.setArrivalDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.hasArrivalDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string shipping_line = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.getShippingLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.setShippingLine = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string equipment = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleContainerDetails.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleClientDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleConsigneeDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseDate: (f = msg.getPurchaseDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentDate: (f = msg.getPaymentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purchasePrice: (f = msg.getPurchasePrice()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPurchaseDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentType(value);
      break;
    case 4:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setPurchasePrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurchasePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp purchase_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.getPurchaseDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.setPurchaseDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.clearPurchaseDate = function() {
  return this.setPurchaseDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.hasPurchaseDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp payment_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.getPaymentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.setPaymentDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.clearPaymentDate = function() {
  return this.setPaymentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.hasPaymentDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string payment_type = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.getPaymentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Money purchase_price = 4;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.getPurchasePrice = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 4));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.setPurchasePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.clearPurchasePrice = function() {
  return this.setPurchasePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehiclePurchaseDetails.prototype.hasPurchasePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    terminal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dispatchedDate: (f = msg.getDispatchedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    promisedPickupDate: (f = msg.getPromisedPickupDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    promisedDelDate: (f = msg.getPromisedDelDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actualDeliveryDate: (f = msg.getActualDeliveryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminal(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDispatchedDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPromisedPickupDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPromisedDelDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualDeliveryDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTerminal();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDispatchedDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPromisedPickupDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPromisedDelDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActualDeliveryDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string terminal = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.getTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.setTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp dispatched_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.getDispatchedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.setDispatchedDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.clearDispatchedDate = function() {
  return this.setDispatchedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.hasDispatchedDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp promised_pickup_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.getPromisedPickupDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.setPromisedPickupDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.clearPromisedPickupDate = function() {
  return this.setPromisedPickupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.hasPromisedPickupDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp promised_del_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.getPromisedDelDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.setPromisedDelDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.clearPromisedDelDate = function() {
  return this.setPromisedDelDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.hasPromisedDelDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp actual_delivery_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.getActualDeliveryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.setActualDeliveryDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.clearActualDeliveryDate = function() {
  return this.setActualDeliveryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleDispatchDetails.prototype.hasActualDeliveryDate = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerNotes: jspb.Message.getFieldWithDefault(msg, 1, ""),
    holdPayment: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    holdLoading: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    consolidateShipment: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    trashRemoval: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    marineInsurance: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerNotes(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoldPayment(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoldLoading(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsolidateShipment(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrashRemoval(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMarineInsurance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerNotes();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHoldPayment();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHoldLoading();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getConsolidateShipment();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTrashRemoval();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMarineInsurance();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string customer_notes = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.getCustomerNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.setCustomerNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool hold_payment = 2;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.getHoldPayment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.setHoldPayment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool hold_loading = 3;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.getHoldLoading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.setHoldLoading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool consolidate_shipment = 4;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.getConsolidateShipment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.setConsolidateShipment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool trash_removal = 5;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.getTrashRemoval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.setTrashRemoval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool marine_insurance = 6;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.getMarineInsurance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleInstructionsDetails.prototype.setMarineInsurance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    lateTitleFees: (f = msg.getLateTitleFees()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    terminalStorageFees: (f = msg.getTerminalStorageFees()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    extraFees: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails;
  return proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setLateTitleFees(value);
      break;
    case 2:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setTerminalStorageFees(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLateTitleFees();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getTerminalStorageFees();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getExtraFees();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Money late_title_fees = 1;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.getLateTitleFees = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 1));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.setLateTitleFees = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.clearLateTitleFees = function() {
  return this.setLateTitleFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.hasLateTitleFees = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Money terminal_storage_fees = 2;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.getTerminalStorageFees = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 2));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.setTerminalStorageFees = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.clearTerminalStorageFees = function() {
  return this.setTerminalStorageFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.hasTerminalStorageFees = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string extra_fees = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.getExtraFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleFeesDetails.prototype.setExtraFees = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sentTo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sentBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification;
  return proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSentTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSentBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sent_to = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.getSentTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.setSentTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sent_by = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.getSentBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.setSentBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email_type = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.getEmailType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleEmailNotification.prototype.setEmailType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customer: (f = msg.getCustomer()) && proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    paidAmount: (f = msg.getPaidAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleOrder;
  return proto.mycargo.projects.erp.api.v1.model.VehicleOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.mycargo.projects.erp.api.v1.model.VehicleCustomer;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 4:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 5:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setPaidAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getPaidAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional VehicleCustomer customer = 3;
 * @return {?proto.mycargo.projects.erp.api.v1.model.VehicleCustomer}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.getCustomer = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.VehicleCustomer, 3));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.VehicleCustomer|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Money amount = 4;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 4));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Money paid_amount = 5;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.getPaidAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 5));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
*/
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.setPaidAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleOrder} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.clearPaidAmount = function() {
  return this.setPaidAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleOrder.prototype.hasPaidAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.VehicleCustomer;
  return proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.VehicleCustomer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.VehicleCustomer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.model);
