// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/customers'

export const getAllData = createAsyncThunk('appCustomers/getAllData', async () => {
  let response = await API.listCustomers()
  return response.customersList
})

export const getData = createAsyncThunk('appCustomers/getData', async (params, { signal }) => {
  let response = await API.listCustomers(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.customersList,
    totalRows: response.totalRows
  }
})

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async name => {
  return await API.getCustomer(name)
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (customer, { dispatch, getState }) => {
  if (customer.type === 'individual') {
    customer.companyDisplayName = ''
  }
  const updated = await API.createCustomer(customer)
  customer = {
    ...customer,
    updated
  }
  await dispatch(getData(getState().customers.params))
  await dispatch(getAllData())
  return customer
})

export const modifyCustomer = createAsyncThunk('appCustomers/modifyCustomer', async (customer, { dispatch, getState }) => {
  if (customer.type === 'individual') {
    customer.companyDisplayName = ''
  }
  const updated = await API.updateCustomer(customer)
  customer = {
    ...customer,
    ...updated
  }
  await dispatch(appCustomersSlice.actions.setSelected(customer))
  await dispatch(getData(getState().customers.params))
  await dispatch(getAllData())
  await dispatch(getCustomer(updated.name))
  return customer
})

export const deleteCustomer = createAsyncThunk('appCustomers/deleteCustomer', async (name, { dispatch, getState }) => {
  await API.deleteCustomer(name)
  await dispatch(getData(getState().customers.params))
  await dispatch(getAllData())
  return name
})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCustomer: null,
    loading: false
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selectedCustomer = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, { payload }) => {
        state.allData = payload
      })
      .addCase(getData.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      // .addCase(getCustomer.fulfilled, (state, { payload }) => {
      //   state.selectedCustomer = payload
      // })
        .addCase(getCustomer.pending, (state) => {
          state.loading = true;
        })
        .addCase(getCustomer.fulfilled, (state, { payload }) => {
          state.selectedCustomer = payload;
          state.loading = false;
        })
        .addCase(getCustomer.rejected, (state) => {
          state.loading = false;
        });

  }
})

export default appCustomersSlice.reducer
