// ** React Imports
import { lazy } from 'react'

const InvoiceTypeList = lazy(() => import('views/references/invoice-types/list'))
const PaymentTypeList = lazy(() => import('views/references/payment-types/list'))
const ServiceTypeList = lazy(() => import('views/references/service-types/list'))
const DiscountTypeList = lazy(() => import('views/references/discount-types/list'))
const FormulasList = lazy(() => import('views/references/formulas/list'))

const ReferenceRoutes = [
  {
    element: <PaymentTypeList />,
    path: '/payment_types',
    meta: {
      resource: 'payment_types',
      action: 'read'
    }
  },
  {
    element: <ServiceTypeList />,
    path: '/service_types',
    meta: {
      resource: 'service_types',
      action: 'read'
    }
  },
  {
    element: <DiscountTypeList />,
    path: '/discount_types',
    meta: {
      resource: 'discount_types',
      action: 'read'
    }
  },
  {
    element: <InvoiceTypeList />,
    path: '/invoice_types',
    meta: {
      resource: 'invoice_types',
      action: 'read'
    }
  },
  {
    element: <FormulasList />,
    path: '/rules',
    meta: {
      resource: 'rules',
      action: 'read'
    }
  }
]

export default ReferenceRoutes
