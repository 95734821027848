import { ApiUrl, getMetadata } from '../index'
import { City } from './city_pb'
import { Country } from './country_pb'
import { State } from './state_pb'
import {
  GetCityRequest,
  GetCountryRequest,
  GetStateRequest,
  ListCountriesRequest,
  ListStatesRequest,
  ListCitiesRequest,
  UpdateCityRequest,
  UpdateCountryRequest,
  UpdateStateRequest
} from './locations_pb'
import { LocationsClient } from './locations_grpc_web_pb'

let client = new LocationsClient(ApiUrl, null, null);

const state2Model = (instance) => {
  let result = new State()
  result.setDisplayName(instance.displayName)
  return result
}

const country2Model = (instance) => {
  let result = new Country()
  result.setDisplayName(instance.displayName)
  return result
}

const city2Model = (instance) => {
  let result = new City()
  result.setDisplayName(instance.displayName)
  return result
}

const API = {
  getCountries: () => {
    const request = new ListCountriesRequest();
    return new Promise((resolve, reject) => {
      client.listCountries(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().countriesList)
        }
      });
    });
  },
  getCountry: (name) => {
    const request = new GetCountryRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCountry(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  updateCountry: (country) => {
    const coun = country2Model(country)
    const request = new UpdateCountryRequest();
    request.setName(country.name)
    request.setCountry(coun)
    return new Promise((resolve, reject) => {
      client.updateCountry(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  getStates: () => {
    const request = new ListStatesRequest();
    return new Promise((resolve, reject) => {
      client.listStates(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().statesList)
        }
      });
    });
  },
  getState: (name) => {
    const request = new GetStateRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getState(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  updateState: (state) => {
    const st = state2Model(state)
    const request = new UpdateStateRequest();
    request.setName(state.name)
    request.setState(st)

    return new Promise((resolve, reject) => {
      client.updateState(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  getCities: () => {
    const request = new ListCitiesRequest();
    return new Promise((resolve, reject) => {
      client.listCities(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().citiesList)
        }
      });
    });
  },
  getCity: (name) => {
    const request = new GetCityRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCity(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  updateCity: (city) => {
    const st = city2Model(city)
    const request = new UpdateCityRequest();
    request.setName(city.name)
    request.setCity(st)
    return new Promise((resolve, reject) => {
      client.updateCity(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
};

export default API;
