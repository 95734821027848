// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/invoices'
import DocumentsAPI from 'api/documents'

export const list = createAsyncThunk('appInvoiceTypes/list', async () => {
  return await API.listInvoiceTypes()
})

export const get = createAsyncThunk('appInvoiceTypes/get', async name => {
  return await API.getInvoiceType(name)
})

export const add = createAsyncThunk('appInvoiceTypes/add', async (instance, { dispatch }) => {
  const updated = await API.createInvoiceType(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appInvoiceTypes/modify', async (instance, { dispatch }) => {
  const updated = await API.updateInvoiceType(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appInvoiceTypesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appInvoiceTypes/del', async (name, { dispatch }) => {
  await API.deleteInvoiceType(name)
  await dispatch(list())
  return name
})

export const getLogos = createAsyncThunk('appInvoiceTypes/getLogos', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: typeName })
  const items = []
  for (let i = 0; i < response.length; i++) {
    const logo = await DocumentsAPI.getDocument(response[i].name)
    items.push(logo)
  }
  const result = {}
  result[typeName] = items
  return result
})

export const addLogo = createAsyncThunk('appInvoiceTypes/addLogo', async (document) => {
  const updated = await DocumentsAPI.createDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteLogo = createAsyncThunk('appInvoiceTypes/deleteLogo', async ({ name, typeName }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getLogos(typeName))
  return name
})

export const appInvoiceTypesSlice = createSlice({
  name: 'appInvoiceTypes',
  initialState: {
    data: [],
    selected: null,
    documents: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
      .addCase(getLogos.fulfilled, (state, { payload }) => {
        state.documents = {
          ...state.documents,
          ...payload
        }
      })
  }
})

export default appInvoiceTypesSlice.reducer
