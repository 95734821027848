// ** React Imports
import { lazy } from 'react'

const CustomerList = lazy(() => import('views/users/customers/list'))
const CustomerView = lazy(() => import('views/users/customers/view'))
const InvoiceAdd = lazy(() => import('views/financial/invoices/add'))
const SuspendedList = lazy(() => import('views/users/suspended/list'))
const PaymentAdd = lazy(() => import('views/financial/payments/add'))

const UserRoutes = [
  {
    element: <CustomerView />,
    path: '/suspended/:id/view',
    meta: {
      resource: 'users',
      action: 'read'
    }
  },
  {
    element: <SuspendedList />,
    path: '/suspended',
    meta: {
      resource: 'users',
      action: 'read'
    }
  },
  {
    element: <PaymentAdd />,
    path: '/customers/:customerId/payments/-/add',
    meta: {
      resource: 'payments',
      action: 'add'
    }
  },
  {
    element: <InvoiceAdd />,
    path: '/customers/:customerId/invoices/-/add',
    meta: {
      resource: 'invoices',
      action: 'add'
    }
  },
  {
    element: <CustomerView />,
    path: '/customers/:id/view',
    meta: {
      resource: 'users',
      action: 'read'
    }
  },
  {
    element: <CustomerList />,
    path: '/customers',
    meta: {
      resource: 'users',
      action: 'read'
    }
  }
]

export default UserRoutes
