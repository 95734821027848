// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/shipments'

export const getData = createAsyncThunk('appContainers/getData', async params => {
  const response = await API.listShipments(params)
  return {
    params,
    data: response.shipmentsList,
    totalRows: response.totalRows
  }
})

export const getContainer = createAsyncThunk('appContainers/getContainer', async name => {
  return await API.getShipment(name)
})

export const appContainersSlice = createSlice({
  name: 'appContainers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedShipment: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getContainer.fulfilled, (state, { payload }) => {
        state.selectedContainer = payload
      })
  }
})

export default appContainersSlice.reducer
