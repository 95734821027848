import { ApiUrl, getMetadata } from '../index'
import { ListLocalTransportationsRequest, ListBuyersRequest } from './cargoloop_pb'
import { CargoloopClient } from './cargoloop_grpc_web_pb'

let client = new CargoloopClient(ApiUrl, null, null);

const API = {
  getLocalTransportations: () => {
    const request = new ListLocalTransportationsRequest();
    return new Promise((resolve, reject) => {
      client.listLocalTransportations(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().localTransportationsList)
        }
      });
    });
  },
  getBuyers: () => {
    const request = new ListBuyersRequest();
    return new Promise((resolve, reject) => {
      client.listBuyers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().buyersList)
        }
      });
    });
  }
};

export default API;
