// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/customers'

export const getAllData = createAsyncThunk('appSuspended/getAllData', async () => {
  let response = await API.listCustomers()
  return response.customersList
})

export const getData = createAsyncThunk('appSuspended/getData', async params => {
  let response = await API.listCustomers({
    ...params,
    status: 'suspended'
  })
  return {
    params,
    data: response.customersList,
    totalRows: response.totalRows
  }
})

export const appSuspendedSlice = createSlice({
  name: 'appSuspended',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: { },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, { payload }) => {
        state.allData = payload
      })
      .addCase(getData.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
  }
})

export default appSuspendedSlice.reducer
