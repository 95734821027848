/**
 * @fileoverview gRPC-Web generated client stub for mycargo.projects.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/formulas.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_formula_pb = require('./formula_pb.js')
const proto = {};
proto.mycargo = {};
proto.mycargo.projects = {};
proto.mycargo.projects.erp = {};
proto.mycargo.projects.erp.api = {};
proto.mycargo.projects.erp.api.v1 = {};
proto.mycargo.projects.erp.api.v1.services = require('./formulas_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.FormulasClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.FormulasPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse>}
 */
const methodDescriptor_Formulas_ListFormulas = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Formulas/ListFormulas',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest,
  proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.FormulasClient.prototype.listFormulas =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/ListFormulas',
      request,
      metadata || {},
      methodDescriptor_Formulas_ListFormulas,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.FormulasPromiseClient.prototype.listFormulas =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/ListFormulas',
      request,
      metadata || {},
      methodDescriptor_Formulas_ListFormulas);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Formula>}
 */
const methodDescriptor_Formulas_GetFormula = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Formulas/GetFormula',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest,
  projects_erp_service_api_v1_model_formula_pb.Formula,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_formula_pb.Formula.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Formula)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Formula>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.FormulasClient.prototype.getFormula =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/GetFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_GetFormula,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Formula>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.FormulasPromiseClient.prototype.getFormula =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/GetFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_GetFormula);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Formula>}
 */
const methodDescriptor_Formulas_CreateFormula = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Formulas/CreateFormula',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest,
  projects_erp_service_api_v1_model_formula_pb.Formula,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_formula_pb.Formula.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Formula)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Formula>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.FormulasClient.prototype.createFormula =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/CreateFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_CreateFormula,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Formula>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.FormulasPromiseClient.prototype.createFormula =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/CreateFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_CreateFormula);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Formula>}
 */
const methodDescriptor_Formulas_UpdateFormula = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Formulas/UpdateFormula',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest,
  projects_erp_service_api_v1_model_formula_pb.Formula,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_formula_pb.Formula.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Formula)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Formula>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.FormulasClient.prototype.updateFormula =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/UpdateFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_UpdateFormula,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Formula>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.FormulasPromiseClient.prototype.updateFormula =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/UpdateFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_UpdateFormula);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse>}
 */
const methodDescriptor_Formulas_DeleteFormula = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Formulas/DeleteFormula',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest,
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.FormulasClient.prototype.deleteFormula =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/DeleteFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_DeleteFormula,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.FormulasPromiseClient.prototype.deleteFormula =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Formulas/DeleteFormula',
      request,
      metadata || {},
      methodDescriptor_Formulas_DeleteFormula);
};


module.exports = proto.mycargo.projects.erp.api.v1.services;

