/**
 * @fileoverview gRPC-Web generated client stub for mycargo.projects.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/locations.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_country_pb = require('./country_pb.js')

var projects_erp_service_api_v1_model_state_pb = require('./state_pb.js')

var projects_erp_service_api_v1_model_city_pb = require('./city_pb.js')
const proto = {};
proto.mycargo = {};
proto.mycargo.projects = {};
proto.mycargo.projects.erp = {};
proto.mycargo.projects.erp.api = {};
proto.mycargo.projects.erp.api.v1 = {};
proto.mycargo.projects.erp.api.v1.services = require('./locations_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListCountriesRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListCountriesResponse>}
 */
const methodDescriptor_Locations_ListCountries = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/ListCountries',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListCountriesRequest,
  proto.mycargo.projects.erp.api.v1.services.ListCountriesResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListCountriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListCountriesResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListCountriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListCountriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.listCountries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/ListCountries',
      request,
      metadata || {},
      methodDescriptor_Locations_ListCountries,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListCountriesResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.listCountries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/ListCountries',
      request,
      metadata || {},
      methodDescriptor_Locations_ListCountries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetCountryRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Country>}
 */
const methodDescriptor_Locations_GetCountry = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/GetCountry',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetCountryRequest,
  projects_erp_service_api_v1_model_country_pb.Country,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_country_pb.Country.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Country)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Country>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.getCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/GetCountry',
      request,
      metadata || {},
      methodDescriptor_Locations_GetCountry,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Country>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.getCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/GetCountry',
      request,
      metadata || {},
      methodDescriptor_Locations_GetCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateCountryRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Country>}
 */
const methodDescriptor_Locations_UpdateCountry = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/UpdateCountry',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateCountryRequest,
  projects_erp_service_api_v1_model_country_pb.Country,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_country_pb.Country.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Country)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Country>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.updateCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/UpdateCountry',
      request,
      metadata || {},
      methodDescriptor_Locations_UpdateCountry,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Country>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.updateCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/UpdateCountry',
      request,
      metadata || {},
      methodDescriptor_Locations_UpdateCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListStatesRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListStatesResponse>}
 */
const methodDescriptor_Locations_ListStates = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/ListStates',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListStatesRequest,
  proto.mycargo.projects.erp.api.v1.services.ListStatesResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListStatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListStatesResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListStatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListStatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListStatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.listStates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/ListStates',
      request,
      metadata || {},
      methodDescriptor_Locations_ListStates,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListStatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListStatesResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.listStates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/ListStates',
      request,
      metadata || {},
      methodDescriptor_Locations_ListStates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetStateRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.State>}
 */
const methodDescriptor_Locations_GetState = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/GetState',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetStateRequest,
  projects_erp_service_api_v1_model_state_pb.State,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_state_pb.State.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.State)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.State>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.getState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/GetState',
      request,
      metadata || {},
      methodDescriptor_Locations_GetState,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.State>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.getState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/GetState',
      request,
      metadata || {},
      methodDescriptor_Locations_GetState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateStateRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.State>}
 */
const methodDescriptor_Locations_UpdateState = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/UpdateState',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateStateRequest,
  projects_erp_service_api_v1_model_state_pb.State,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_state_pb.State.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.State)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.State>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.updateState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/UpdateState',
      request,
      metadata || {},
      methodDescriptor_Locations_UpdateState,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.State>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.updateState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/UpdateState',
      request,
      metadata || {},
      methodDescriptor_Locations_UpdateState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListCitiesRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListCitiesResponse>}
 */
const methodDescriptor_Locations_ListCities = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/ListCities',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListCitiesRequest,
  proto.mycargo.projects.erp.api.v1.services.ListCitiesResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListCitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListCitiesResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListCitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListCitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.listCities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/ListCities',
      request,
      metadata || {},
      methodDescriptor_Locations_ListCities,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListCitiesResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.listCities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/ListCities',
      request,
      metadata || {},
      methodDescriptor_Locations_ListCities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetCityRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.City>}
 */
const methodDescriptor_Locations_GetCity = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/GetCity',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetCityRequest,
  projects_erp_service_api_v1_model_city_pb.City,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_city_pb.City.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.City)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.City>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.getCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/GetCity',
      request,
      metadata || {},
      methodDescriptor_Locations_GetCity,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.City>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.getCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/GetCity',
      request,
      metadata || {},
      methodDescriptor_Locations_GetCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateCityRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.City>}
 */
const methodDescriptor_Locations_UpdateCity = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Locations/UpdateCity',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateCityRequest,
  projects_erp_service_api_v1_model_city_pb.City,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_city_pb.City.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.City)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.City>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.LocationsClient.prototype.updateCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/UpdateCity',
      request,
      metadata || {},
      methodDescriptor_Locations_UpdateCity,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.City>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.LocationsPromiseClient.prototype.updateCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Locations/UpdateCity',
      request,
      metadata || {},
      methodDescriptor_Locations_UpdateCity);
};


module.exports = proto.mycargo.projects.erp.api.v1.services;

