import {DefaultRoute} from "../router/routes";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
    num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

export const formatMoney = (m) => {
    if (!m) {
        return null;
    }
    const val = m.value * Math.pow(10, m.exponent);
    const cur = (m.currencyName ?? "").replace("currencies/", "");
    const locale = val.toLocaleString(undefined, {minimumFractionDigits: 0});
    if (cur === "usd") {
        return locale.startsWith("-") ? `-$${locale.substring(1)}` : `$${locale}`;
    } else {
        return `${locale} ${cur?.toUpperCase()}`;
    }
};

export const currencySymbol = (m) => {
    const cur = (m?.currencyName ?? "").replace("currencies/", "");
    if (cur === "usd") return "$";
    else return cur?.toUpperCase();
};

export const formatMeasure = (m) => {
    if (!m) {
        return null;
    }
    const val = m.value * Math.pow(10, m.exponent);
    const cur = (m.unitName ?? "").replace("units/", "");
    const locale = val.toLocaleString(undefined, {minimumFractionDigits: 0});
    return `${locale} ${cur?.toLowerCase()}`;
};

export const measureSymbol = (m) => {
    const unt = (m?.unitName ?? "").replace("units/", "");
    return unt?.toLowerCase();
};

export const defaultWeightMeasure = () => ({
    unitName: "units/kg",
    exponent: -1,
});

export const defaultLengthMeasure = () => ({
    unitName: "units/cm",
    exponent: 0,
});

export const defaultQuantityMeasure = () => ({
    unitName: "units/pcs",
    exponent: 0,
});

export const defaultMoney = () => ({
    currencyName: "currencies/usd",
    exponent: -2,
});

export const createMoney = (value, money) => {
    return {
        ...money,
        value: Math.round(value * Math.pow(10, -money.exponent)) | 0,
    };
};

export const parseMoneyValue = (value, money) => {
    // console.log(value, "VAL")
    // console.log(money, "money")
    if (!value) {
        return [createMoney(0, money), null];
    }
    let formatted = value.replace(",", ".").replace(/[^\d.-]/g, "");
    // console.log(formatted,"formatted")
    if (formatted.indexOf(".") !== formatted.lastIndexOf(".")) {
        formatted = formatted.substring(
            0,
            formatted.indexOf(".", formatted.indexOf(".") + 1)
        );
    }
    if (
        formatted.indexOf(".") >= 0 &&
        formatted.length > formatted.indexOf(".") - money.exponent + 1
    ) {
        formatted = formatted.substring(
            0,
            formatted.indexOf(".") - money.exponent + 1
        );
    }
    return [
        createMoney(parseFloat(formatted), money),
        (!!formatted && formatted.endsWith(".")) || formatted.endsWith("0")
            ? formatted
            : null,
    ];
};

export const moneyValue = (m) => {
    if (!m) {
        return null;
    }
    return parseFloat(
        (m.value * Math.pow(10, m.exponent)).toFixed(-defaultMoney().exponent)
    );
};

export const dateToTimestamp = (v) => {
    if (!v) return null;
    let date = new Date(v);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - userTimezoneOffset);
    const time = date.getTime();

    return {seconds: time / 1000, nanos: (time % 1000) * 1e6};
};

export const timestampToDate = (v) => {
    if (!v) return null;
    var d = new Date(v.seconds * 1000);
    d.setMilliseconds(v.nanos / 1e6);
    return d;
};

export const timestampToProto = (v) => {
    if (!v) return null;
    const dtm = timestampToDate(v);
    const proto = window.proto;
    const date = new proto.google.protobuf.Timestamp();
    date.fromDate(dtm);
    return date;
};

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatTimestamp = (v) => {
    const date = timestampToDate(v);
    if (!date) return "-";
    const [yyyy, mm, dd] = date.toISOString().split(/[/:\-T]/);
    return `${dd}-${monthNames[mm - 1].substring(0, 3)}-${yyyy.substring(2, 4)}`;
};

export const formatUsername = (username) => {
    if (!username) return null;
    return username
        .split("_")
        .map((e) => e.substring(0, 1).toUpperCase() + e.substring(1).toLowerCase())
        .reduce((a, e) => a + (a ? " " : "") + e, "");
};

export const toMap = (protoMap) => {
    if (!protoMap) {
        return {};
    }
    const result = {};
    for (const i in protoMap) {
        result[protoMap[i][0]] = protoMap[i][1];
    }
    return result;
};

export const formatMap = (protoMap) => {
    if (!protoMap) {
        return {};
    }
    const map = toMap(protoMap);

    let result = [];
    for (const key in map) {
        result.push(`${key}: ${map[key]}`);
        result.push(<br/>);
    }
    if (result.length > 0) {
        result.splice(result.length - 1, 1);
    }

    return result;
};

export const formatMapValue = (protoMap) => {
    if (!protoMap) {
        return {};
    }
    const map = toMap(protoMap);

    let result = [];
    for (const key in map) {
        result.push(`${key}: ${map[key]}`);
    }

    return result.reduce((a, e) => a + (a ? "\r\n" : "") + e, "");
};

export const mapToProto = (v) => {
    const result = [];
    for (const key in v) {
        result.push([key, v[key]]);
    }
    return result;
};

export const dateToIso = (v) => {
    if (!v) return null;
    try {
        let date = new Date(v);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        date = new Date(date.getTime() - userTimezoneOffset);
        return new Date(date).toISOString().substring(0, 10);
    } catch {
        return null;
    }
};

export const formatIsoDate = (v) => {
    if (!v) return "-";
    const d = new Date(v);
    return d.toLocaleDateString();
};

export function getDateXDaysAgo(eventDate, date = new Date()) {
    if (!eventDate) return "-";

    let difference = date.getTime() - eventDate.getTime();
    if (difference < 0) return null;
    let days = Math.ceil(difference / (1000 * 3600 * 24));
    if (days > 0) return `${days} days ago`;

    const hours = Math.ceil(difference / (1000 * 3600));
    if (hours > 0) return `${hours} hours ago`;

    const mins = Math.ceil(difference / (1000 * 60));
    if (mins > 0) return `${mins} mins ago`;

    return "now";
}

export function isTimestampPassed(ts, date = new Date()) {
    if (!ts) return false;
    const eventDate = timestampToDate(ts);
    return eventDate.getTime() < date.getTime();
}

export function getTimestampDaysAgo(ts, date = new Date()) {
    const eventDate = timestampToDate(ts);
    return getDateXDaysAgo(eventDate, date);
}

export function getIsoDaysAgo(d, date = new Date()) {
    if (!d) return "-";
    const eventDate = new Date(d);
    return getDateXDaysAgo(eventDate, date);
}

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date();
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    );
};

/** Checks property value contains formula filter value.
 * @param {string} propertyValue
 * @param {string} formulaValue
 * @returns {bool}
 */
export const checkFormulaFilterValue = (propertyValue, formulaValue) => {
    if (propertyValue === formulaValue) return true;
    if (
        propertyValue &&
        formulaValue &&
        propertyValue.split(";").filter((v) => v.trim() === formulaValue).length > 0
    )
        return true;
    return false;
};

/** Splits a camel-case or Pascal-case variable name into individual words.
 * @param {string} s
 * @returns {string[]}
 */
export const splitWords = (s) => {
    var re,
        match,
        output = [];
    // re = /[A-Z]?[a-z]+/g
    re = /([A-Za-z]?)([a-z]+)/g;

    /*
    matches example: "oneTwoThree"
    ["one", "o", "ne"]
    ["Two", "T", "wo"]
    ["Three", "T", "hree"]
    */

    match = re.exec(s);
    while (match) {
        // output.push(match.join(""));
        output.push([match[1].toUpperCase(), match[2]].join(""));
        match = re.exec(s);
    }

    return output;
};

const DESCRIPTION_DELIMITER = "--";
export const tryParseCustomerExtId = (description) => {
    if (!description) return null;
    const ind = description.lastIndexOf(DESCRIPTION_DELIMITER);
    if (ind === -1) return null;
    const prefix = description
        .substring(ind + DESCRIPTION_DELIMITER.length)
        .trim();
    return prefix.toLowerCase();
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = {month: "short", day: "numeric", year: "numeric"}
) => {
    if (!value) return value;
    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting = {month: "short", day: "numeric"};

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: "numeric", minute: "numeric"};
    }

    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === "admin") return DefaultRoute;
    if (userRole === "client") return "/access-control";
    return DefaultRoute;
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: "#7367f01a", // for option hover bg-color
        primary: "#7367f0", // for selected option bg-color
        neutral10: "#7367f0", // for tags bg-color
        neutral20: "#ededed", // for input border-color
        neutral30: "#ededed", // for input hover border-color
    },
});

/** Generates random ID **/
export const idGen = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

// export const invoiceMax = (order, paymentInvoice, i) => {
//   const invoiceRest = moneyValue(paymentInvoice.amount) - moneyValue(paymentInvoice.paidAmount)
//   const invoicePrev = prevOrders
//       .filter(e => e.orderName === order.name && e.invoiceName === paymentInvoice.name)
//       .reduce((a, e) => a + moneyValue(e.amount), 0)
//   const invoiceOthers = payment
//       .ordersList
//       .filter((_, ind) => i != ind)
//       .filter(e => e.orderName === order.name && e.invoiceName === paymentInvoice.name)
//       .reduce((a, e) => a + moneyValue(e.amount), 0)
//   return invoiceRest + invoicePrev - invoiceOthers
// }

export const orderItemMax = (
    order,
    itemName,
    i,
    payments,
    payment,
    serviceTypes
) => {
    // console.count("_______________ @@@@@@@@@@@@@@@@@ _____________________ orderItemMax")
    let orderAmt = 0;
    if (itemName.startsWith("cargoloop/vehicles/")) {
        orderAmt = order.itemsList
            .filter((e) => e.itemName === itemName)
            .reduce((a, e) => a + moneyValue(e.amount), 0);
    } else if (itemName.startsWith("serviceTypes/")) {
        orderAmt = order.servicesList
            .filter((e) => e.serviceTypeName === itemName)
            .reduce((a, e) => a + moneyValue(e.amount), 0);
    } else if (itemName.startsWith("serviceGroups/")) {
        const group = itemName.replace("serviceGroups/", "");
        orderAmt = order.servicesList
            .filter(
                (e) =>
                    serviceTypes.filter((s) => s.name === e.serviceTypeName)[0]
                        ?.groupDisplayName === group
            )
            .reduce((a, e) => a + moneyValue(e.amount), 0);
    }
    const orderPaid = payments
        .flatMap((e) => e.ordersList)
        .filter((e) => e.orderName === order.name && e.itemName === itemName)
        .reduce((a, e) => a + moneyValue(e.amount), 0);
    const orderRest = orderAmt - orderPaid;
    const orderOthers = payment.ordersList
        .filter((_, ind) => i != ind)
        .filter((e) => e.orderName === order.name && e.itemName === itemName)
        .reduce((a, e) => a + moneyValue(e.amount), 0);
    return orderRest - orderOthers;
};

export const orderMax = (order, i, prevOrders, payment) => {
    const orderRest = moneyValue(order.amount) - moneyValue(order.paidAmount);
    // const orderPrev = prevOrders
    //     .filter(e => e.orderName === order.name)
    //     .reduce((a, e) => a + moneyValue(e.amount), 0)
    const orderPrev = prevOrders.reduce((total, orderItem) => {
        if (orderItem.orderName === order.name) {
            return total + moneyValue(orderItem.amount);
        }
        return total;
    }, 0);

    // const orderOthers = payment
    //     .ordersList
    //     .filter((_, ind) => i != ind)
    //     .filter(e => e.orderName === order.name)
    //     .reduce((a, e) => a + moneyValue(e.amount), 0)
    const orderOthers = payment.ordersList.reduce((total, orderItem, index) => {
        if (index !== i && orderItem.orderName === order.name) {
            return total + moneyValue(orderItem.amount);
        }
        return total;
    }, 0);

    return orderRest + orderPrev - orderOthers;
};

export const invoiceMax = (order, paymentInvoice, i, prevOrders, payment) => {
    const invoiceRest =
        moneyValue(paymentInvoice.amount) - moneyValue(paymentInvoice.paidAmount);
    const invoicePrev = prevOrders
        .filter(
            (e) => e.orderName === order.name && e.invoiceName === paymentInvoice.name
        )
        .reduce((a, e) => a + moneyValue(e.amount), 0);
    const invoiceOthers = payment.ordersList
        .filter((_, ind) => i != ind)
        .filter(
            (e) => e.orderName === order.name && e.invoiceName === paymentInvoice.name
        )
        .reduce((a, e) => a + moneyValue(e.amount), 0);
    return invoiceRest + invoicePrev - invoiceOthers;
};

export const calculateAmountForCustomer = (array, statusTitle) => {
    return array?.reduce(
        (acc, payment) => {
            if (payment.status === statusTitle && payment.amount) {
                const {value, currencyName, exponent} = payment.amount;
                acc.value += value;
                acc.currencyName = currencyName;
                acc.exponent = exponent;
                acc.status = payment.status;
            }
            return acc;
        },
        {value: 0, currencyName: "", exponent: 0}
    );
};

export const calculateAmountForOrders = (array, statusTitle) => {
    return array?.reduce((acc, el) => {
        if (el.status === statusTitle && el.ordersList) {
            el.ordersList.forEach((order) => {
                if (order.amount) {
                    const {orderName, amount} = order;
                    if (acc[orderName]) {
                        acc[orderName].value += amount.value;
                    } else {
                        acc[orderName] = {
                            value: amount.value,
                            currencyName: amount.currencyName,
                            exponent: amount.exponent,
                        };
                    }
                }
            });
        }
        return acc;
    }, {});
};

export const payableStatusColors = {
    pending: "light-danger",
    paid: "light-success",
    reverted: "light-secondary",
    cancelled: "light-warning",
};

export const getExactOrderName = (arr) => {

    return arr?.reduce((acc, el) => {
        if (el.ordersList) {
            el.ordersList.forEach((order) => {
                if (order.amount) {
                    const {orderName, amount} = order;
                    if (acc[orderName]) {
                        acc[orderName].value += amount.value;
                    } else {
                        acc[orderName] = {
                            value: amount.value,
                            currencyName: amount.currencyName,
                            exponent: amount.exponent,
                        };
                    }
                }
            });
        }
        return acc;
    }, {});
};


export const splitValue = (value) => {
    return value.split("/")[1]
}


