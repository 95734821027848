// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/terminals'

export const getData = createAsyncThunk('appTerminals/getData', async (_, { getState }) => {
  let terminals = await API.getTerminals()
  let cities = getState().cities
  let states = getState().states
  let countries = getState().countries
  terminals.forEach((e) => {
    e.cityDisplayName = cities?.data?.filter((c) => c.name === e.cityName)[0]?.displayName
    e.stateDisplayName = states?.data?.filter((c) => c.name === e.stateName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return terminals
})

export const appTerminalsSlice = createSlice({
  name: 'appTerminals',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appTerminalsSlice.reducer
