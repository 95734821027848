// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import ApprovalsAPI from 'api/approvals'
import DocumentsAPI from 'api/documents'
import API from 'api/payments'

export const getData = createAsyncThunk(
    'appPayments/getData',
    async (params, {signal}) => {
        const response = await API.listPayments(params)
        if (signal.aborted) return
        return {
            params,
            data: response.paymentsList,
            totalRows: response.totalRows,
            totalAmount: response.totalAmount,
        }
    }
)

export const getPayment = createAsyncThunk(
    'appPayments/getPayment',
    async name => {
        return await API.getPayment(name)
    }
)

export const addPayment = createAsyncThunk(
    'appPayments/addPayment',
    async (payment, {dispatch, getState}) => {
        await dispatch(appPaymentsSlice.actions.setCreating(true))
        const updated = await API.createPayment(payment)
        payment = {
            ...payment,
            ...updated,
        }
        await dispatch(appPaymentsSlice.actions.setCreating(false))
        await dispatch(getData(getState().payments.params))
        return payment
    }
)

export const modifyPayment = createAsyncThunk(
    'appPayments/modifyPayment',
    async (payment, {dispatch, getState}) => {
        await dispatch(appPaymentsSlice.actions.setUpdating(true))
        const updated = await API.updatePayment(payment)
        payment = {
            ...payment,
            ...updated,
        }
        await dispatch(appPaymentsSlice.actions.setUpdating(false))
        await dispatch(appPaymentsSlice.actions.setSelected(payment))
        await dispatch(getData(getState().payments.params))
        if (getState().payments.customers.hasOwnProperty(payment.customerName)) {
            await dispatch(getCustomerPayments(payment.customerName))
        }
    }
)

export const deletePayment = createAsyncThunk(
    'appPayments/deletePayment',
    async (name, {dispatch, getState}) => {
        await API.deletePayment(name)
        await dispatch(getData(getState().payments.params))
        return name
    }
)

export const getCustomerPayments = createAsyncThunk(
    'appPayments/getCustomerPayments',
    async customerName => {
        const response = await API.getCustomerPayments(customerName)
        const result = {}
        result[customerName] = response
        return result
    }
)

export const getOrderPayments = createAsyncThunk(
    'appPayments/getOrderPayments',
    async orderName => {
        const response = await API.getOrderPayments(orderName)
        const result = {}
        result[orderName] = response
        return result
    }
)

export const getPaymentApprovals = createAsyncThunk(
    'appPayments/getPaymentApprovals',
    async paymentName => {
        const response = await ApprovalsAPI.listApprovals({
            entityName: paymentName,
        })
        const result = {}
        result[paymentName] = response
        return result
    }
)

export const addPaymentApproval = createAsyncThunk(
    'appPayments/addPaymentApproval',
    async (approval, {dispatch, getState}) => {
        const updated = await ApprovalsAPI.createApproval(approval)
        approval = {
            ...approval,
            ...updated,
        }
        if (getState().payments.approvals.hasOwnProperty(approval.entityName)) {
            await dispatch(getPaymentApprovals(approval.entityName))
        }
        return approval
    }
)

export const deletePaymentApproval = createAsyncThunk(
    'appPayments/deletePaymentApproval',
    async (approval, {dispatch, getState}) => {
        await ApprovalsAPI.deleteApproval(approval.name)
        if (getState().payments.approvals.hasOwnProperty(approval.entityName)) {
            await dispatch(getPaymentApprovals(approval.entityName))
        }
        return approval
    }
)

export const getPaymentDocuments = createAsyncThunk(
    'appPayments/getPaymentDocuments',
    async paymentName => {
        const response = await DocumentsAPI.listDocuments({location: paymentName})
        const result = {}
        result[paymentName] = response
        return result
    }
)

export const getPaymentDocument = createAsyncThunk(
    'appPayments/getPaymentDocument',
    async name => {
        return await DocumentsAPI.getDocument(name)
    }
)

export const addPaymentDocument = createAsyncThunk(
    'appPayments/addPaymentDocument',
    async document => {
        const updated = await DocumentsAPI.createDocument(document)
        document = {
            ...document,
            ...updated,
        }
        return document
    }
)

export const deletePaymentDocument = createAsyncThunk(
    'appPayments/addPaymentDocument',
    async ({name, paymentName}, {dispatch}) => {
        await DocumentsAPI.deleteDocument(name)
        await dispatch(getPaymentDocuments(paymentName))
        return name
    }
)

export const getPaymentEmployees = createAsyncThunk(
    'appPayments/getPaymentEmployees',
    async () => {
        return await API.listPaymentEmployees()
    }
)

export const appPaymentsSlice = createSlice({
    name: 'appPayments',
    initialState: {
        data: [],
        total: 1,
        totalAmount: null,
        params: {},
        customers: {},
        orders: {},
        approvals: {},
        documents: {},
        selectedPayment: null,
        employees: [],
        creating: false,
        updating: false,
        loading: false,
        approvalStatus: false
    },
    reducers: {
        setSelected: (state, {payload}) => {
            state.selectedPayment = payload
        },
        setCreating: (state, {payload}) => {
            state.creating = payload
        },
        setUpdating: (state, {payload}) => {
            state.updating = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.total = action.payload.totalRows
                state.totalAmount = action.payload.totalAmount
                state.params = action.payload.params
            })
            .addCase(getCustomerPayments.fulfilled, (state, {payload}) => {
                state.customers = {
                    ...state.customers,
                    ...payload,
                }
            })
            .addCase(getOrderPayments.fulfilled, (state, {payload}) => {
                state.orders = {
                    ...state.orders,
                    ...payload,
                }
            })
            .addCase(getPaymentApprovals.fulfilled, (state, {payload}) => {
                state.approvals = {
                    ...state.approvals,
                    ...payload,
                }
            })
            .addCase(getPayment.pending, state => {
                state.loading = true
            })
            .addCase(getPayment.fulfilled, (state, {payload}) => {
                state.selectedPayment = payload
                state.loading = false
            })
            .addCase(getPayment.rejected, state => {
                state.loading = false
            })
            .addCase(getPaymentDocuments.fulfilled, (state, {payload}) => {
                state.documents = {
                    ...state.documents,
                    ...payload,
                }
            })
            .addCase(getPaymentEmployees.fulfilled, (state, action) => {
                state.employees = action.payload
            })
            .addCase(deletePaymentApproval.pending, (state) => {
                state.approvalStatus = true
            })
            .addCase(deletePaymentApproval.fulfilled, (state) => {
                state.approvalStatus = false
            })
            .addCase(deletePaymentApproval.rejected, (state) => {
                state.approvalStatus = false
            })
            .addCase(addPaymentApproval.pending, (state) => {
                state.approvalStatus = true
            })
            .addCase(addPaymentApproval.fulfilled, (state) => {
                state.approvalStatus = false
            })
            .addCase(addPaymentApproval.rejected, (state) => {
                state.approvalStatus = false
            })
    },
})
export default appPaymentsSlice.reducer
