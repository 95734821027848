// ** Icons Import
import { Archive, Briefcase, CreditCard, DollarSign, PenTool } from 'react-feather'

export default [
    {
        header: 'References',
        resource: 'references',
        action: 'read'
    },
    {
        id: 'payment_types',
        title: 'Payment Types',
        icon: <CreditCard size={20} />,
        navLink: '/payment_types',
        resource: 'payment_types',
        action: 'read'
    },
    {
        id: 'service_types',
        title: 'Service Types',
        icon: <Briefcase size={20} />,
        navLink: '/service_types',
        resource: 'service_types',
        action: 'read'
    },
    {
        id: 'discount_types',
        title: 'Discount Types',
        icon: <DollarSign size={20} />,
        navLink: '/discount_types',
        resource: 'discount_types',
        action: 'read'
    },
    {
        id: 'invoice_types',
        title: 'Invoice Types',
        icon: <Archive size={20} />,
        navLink: '/invoice_types',
        resource: 'invoice_types',
        action: 'read'
    },
    {
        id: 'rules',
        title: 'Rules',
        icon: <PenTool size={20} />,
        navLink: '/rules',
        resource: 'rules',
        action: 'read'
    }
]
