// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/invoices'

export const getData = createAsyncThunk('appInvoices/getData', async (params, { signal }) => {
  const response = await API.listInvoices(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.invoicesList,
    totalRows: response.totalRows
  }
})

export const getInvoice = createAsyncThunk('appInvoices/getInvoice', async name => {
  return await API.getInvoice(name)
})

export const addInvoice = createAsyncThunk('appInvoices/addInvoice', async (instance, { dispatch, getState }) => {
  await dispatch(appInvoicesSlice.actions.setCreating(true))
  const updated = await API.createInvoice(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(getData(getState().invoices.params))
  await dispatch(appInvoicesSlice.actions.setCreating(false))
  return instance
})

export const modifyInvoice = createAsyncThunk('appInvoices/modifyInvoice', async (instance, { dispatch, getState }) => {
  await dispatch(appInvoicesSlice.actions.setUpdating(true))
  const updated = await API.updateInvoice(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appInvoicesSlice.actions.setSelected(instance))
  await dispatch(getData(getState().invoices.params))
  if (getState().invoices.customers.hasOwnProperty(instance.customerName)) {
    await dispatch(getCustomerInvoices(instance.customerName))
  }
  await dispatch(appInvoicesSlice.actions.setUpdating(false))
})

export const deleteInvoice = createAsyncThunk('appInvoices/deleteInvoice', async (name, { dispatch, getState }) => {
  await API.deleteInvoice(name)
  await dispatch(getData(getState().invoicess.params))
  return name
})

export const getCustomerInvoices = createAsyncThunk('appInvoices/getCustomerInvoices', async customerName => {
  const response = await API.getCustomerInvoices(customerName)
  const result = {}
  result[customerName] = response
  return result
})

export const getOrderInvoices = createAsyncThunk('appInvoices/getOrderInvoices', async orderName => {
  const response = await API.getOrderInvoices(orderName)
  const result = {}
  result[orderName] = response
  return result
})

export const appInvoicesSlice = createSlice({
  name: 'appInvoices',
  initialState: {
    data: [],
    total: 1,
    params: {},
    customers: {},
    orders: {},
    selectedInvoice: null,
    creating: false,
    updating: false,
    documents: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selectedInvoice = payload
    },
    setCreating: (state, { payload }) => {
      state.creating = payload
    },
    setUpdating: (state, { payload }) => {
      state.updating = payload
    }
  }, 
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalRows
        state.params = action.payload.params
      })
      .addCase(getCustomerInvoices.fulfilled, (state, { payload }) => {
        state.customers = {
          ...state.customers,
          ...payload
        }
      })
      .addCase(getOrderInvoices.fulfilled, (state, { payload }) => {
        state.orders = {
          ...state.orders,
          ...payload
        }
      })
      .addCase(getInvoice.fulfilled, (state, { payload }) => {
        state.selectedInvoice = payload
      })
  }
})

export default appInvoicesSlice.reducer
