import {ApiUrl, getMetadata} from '../index'
import {Payment, PaymentOrder, PaymentType} from './payment_pb'
import {
    ListPaymentsRequest,
    GetPaymentRequest,
    CreatePaymentRequest,
    UpdatePaymentRequest,
    DeletePaymentRequest,
    ListPaymentTypesRequest,
    GetPaymentTypeRequest,
    CreatePaymentTypeRequest,
    UpdatePaymentTypeRequest,
    DeletePaymentTypeRequest,
    ListPaymentEmployeesRequest
} from './payments_pb'
import {Money} from '../money_pb'
import {PaymentsClient} from './payments_grpc_web_pb'

// ** Utility
import {idGen, timestampToProto} from 'utility/utils'

let client = new PaymentsClient(ApiUrl, null, null);

const payment2Model = (payment) => {
    let pmt = new Payment()
    if (payment.amount?.value) {
        let amt = new Money()
        amt.setValue(payment.amount.value)
        amt.setCurrencyName(payment.amount.currencyName)
        amt.setExponent(payment.amount.exponent)
        pmt.setAmount(amt)
    }
    pmt.setDisplayName(payment.displayName ?? '')
    pmt.setCustomerName(payment.customerName ?? '')
    pmt.setPaymentTypeName(payment.paymentTypeName ?? '')
    pmt.setStatus(payment.status ?? '')
    pmt.setComment(payment.comment ?? '')
    if (payment.date)
        pmt.setDate(timestampToProto(payment.date))
    if (payment.ordersList) {
        for (const index in payment.ordersList) {
            const order = payment.ordersList[index]
            const ord = new PaymentOrder()
            ord.setOrderName(order.orderName ?? '')
            ord.setInvoiceName(order.invoiceName ?? '')
            ord.setItemName(order.itemName ?? '')
            let amt = new Money()
            amt.setValue(order.amount.value)
            amt.setCurrencyName(order.amount.currencyName)
            amt.setExponent(order.amount.exponent)
            ord.setAmount(amt)
            pmt.addOrders(ord)
        }
    }

    return pmt
}

const paymentType2Model = (instance) => {
    let result = new PaymentType()
    result.setName(instance.name)
    result.setCode(instance.code)
    result.setDisplayName(instance.displayName)
    result.setIsActive(instance.isActive)
    return result
}

const API = {
    listPayments: (params) => {
        const request = new ListPaymentsRequest()
        if (params?.page) {
            request.setPage(params.page)
        }
        if (params?.perPage) {
            request.setPerPage(params.perPage)
        }
        if (params?.sort) {
            request.setSort(params.sort)
        }
        if (params?.sortColumn) {
            request.setSortColumn(params.sortColumn)
        }
        if (params?.customerName) {
            request.setCustomerName(params.customerName)
        }
        if (params?.status) {
            request.setStatus(params.status)
        }
        if (params?.paymentTypeName) {
            request.setPaymentTypeName(params.paymentTypeName)
        }
        if (params?.dateFrom)
            request.setDateFrom(timestampToProto(params?.dateFrom))
        if (params?.dateTo)
            request.setDateTo(timestampToProto(params?.dateTo))
        if (params?.amount) {
            const amt = new Money()
            amt.setValue(params?.amount.value)
            amt.setCurrencyName(params?.amount.currencyName)
            amt.setExponent(params?.amount.exponent)
            request.setAmount(amt)
        }
        if (params?.approvedOnly) {
            request.setApprovedOnly(params.approvedOnly)
        } else if (params?.unapprovedOnly) {
            request.setUnapprovedOnly(params.unapprovedOnly)
        }
        if (params?.createdBy) {
            request.setCreatedBy(params.createdBy)
        }
        if (params?.search) {
            request.setSearch(params.search)
        }
        return new Promise((resolve, reject) => {
            client.listPayments(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    const clonedObject = JSON.parse(JSON.stringify(response.toObject()))
                    const newSortedOrder = clonedObject.paymentsList.sort((a, b) => {
                        if (a.date.seconds > b.date.seconds) return -1
                        else if (a.date.seconds < b.date.seconds) return 1
                        else return 0

                    })
                    const newSortedObject = {
                        ...clonedObject,
                        paymentsList: newSortedOrder
                    }
                    // resolve(response.toObject())
                    resolve(newSortedObject)
                }
            })
        })
    },
    getCustomerPayments: (customerName) => {
        const request = new ListPaymentsRequest()
        request.setCustomerName(customerName)
        return new Promise((resolve, reject) => {
            client.listPayments(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject().paymentsList)
                }
            })
        })
    },
    getOrderPayments: (orderName) => {
        const request = new ListPaymentsRequest()
        request.setOrderName(orderName)
        return new Promise((resolve, reject) => {
            client.listPayments(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject().paymentsList)
                }
            })
        })
    },
    getPayment: (name) => {
        const request = new GetPaymentRequest()
        request.setName(name)
        return new Promise((resolve, reject) => {
            client.getPayment(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    /*setting an ID to ordersList*/
                    const item = response.toObject()
                    item.ordersList = item.ordersList.map(el => {
                        return {
                            ...el,
                            id: idGen()
                        }
                    })
                    // resolve(response.toObject())
                    resolve(item)
                }
            });
        });
    },
    createPayment: (payment) => {
        const pmt = payment2Model(payment)
        const request = new CreatePaymentRequest()
        request.setPayment(pmt)

        const metadata = getMetadata()
        if (payment?.requestId) {
            metadata["X-RequestId"] = payment.requestId
        }
        return new Promise((resolve, reject) => {
            client.createPayment(request, metadata, (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },
    updatePayment: (payment) => {
        const pmt = payment2Model(payment)
        const request = new UpdatePaymentRequest();
        request.setName(payment.name)
        request.setPayment(pmt)

        return new Promise((resolve, reject) => {
            client.updatePayment(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },
    deletePayment: (name) => {
        const request = new DeletePaymentRequest();
        request.setName(name)

        return new Promise((resolve, reject) => {
            client.deletePayment(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },

    listPaymentTypes: () => {
        const request = new ListPaymentTypesRequest()
        return new Promise((resolve, reject) => {
            client.listPaymentTypes(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject().paymentTypesList)
                }
            })
        })
    },
    getPaymentType: (name) => {
        const request = new GetPaymentTypeRequest()
        request.setName(name)
        return new Promise((resolve, reject) => {
            client.getPaymentType(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },
    createPaymentType: (paymentType) => {
        const paym = paymentType2Model(paymentType)
        const request = new CreatePaymentTypeRequest()
        request.setPaymentType(paym)

        return new Promise((resolve, reject) => {
            client.createPaymentType(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    updatePaymentType: (paymentType) => {
        const paym = paymentType2Model(paymentType)
        const request = new UpdatePaymentTypeRequest();
        request.setName(paymentType.name)
        request.setPaymentType(paym)

        return new Promise((resolve, reject) => {
            client.updatePaymentType(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    deletePaymentType: (name) => {
        const request = new DeletePaymentTypeRequest();
        request.setName(name)

        return new Promise((resolve, reject) => {
            client.deletePaymentType(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },

    listPaymentEmployees: () => {
        const request = new ListPaymentEmployeesRequest()
        return new Promise((resolve, reject) => {
            client.listPaymentEmployees(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject().employeesList)
                }
            })
        })
    }
};

export default API;
