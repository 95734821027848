// source: projects/erp/service/api/v1/services/cargoloop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var projects_erp_service_api_v1_model_cl_buyer_pb = require('./cl_buyer_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_cl_buyer_pb);
var projects_erp_service_api_v1_model_cl_localtransportation_pb = require('./cl_localtransportation_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_cl_localtransportation_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest;
  return proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    localTransportationsList: jspb.Message.toObjectList(msg.getLocalTransportationsList(),
    projects_erp_service_api_v1_model_cl_localtransportation_pb.LocalTransportation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse;
  return proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_cl_localtransportation_pb.LocalTransportation;
      reader.readMessage(value,projects_erp_service_api_v1_model_cl_localtransportation_pb.LocalTransportation.deserializeBinaryFromReader);
      msg.addLocalTransportations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalTransportationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_cl_localtransportation_pb.LocalTransportation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated mycargo.projects.erp.api.v1.model.LocalTransportation local_transportations = 1;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.LocalTransportation>}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.prototype.getLocalTransportationsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.LocalTransportation>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_cl_localtransportation_pb.LocalTransportation, 1));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.LocalTransportation>} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse} returns this
*/
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.prototype.setLocalTransportationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.LocalTransportation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.LocalTransportation}
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.prototype.addLocalTransportations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mycargo.projects.erp.api.v1.model.LocalTransportation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListLocalTransportationsResponse.prototype.clearLocalTransportationsList = function() {
  return this.setLocalTransportationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest;
  return proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    buyersList: jspb.Message.toObjectList(msg.getBuyersList(),
    projects_erp_service_api_v1_model_cl_buyer_pb.Buyer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse;
  return proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_cl_buyer_pb.Buyer;
      reader.readMessage(value,projects_erp_service_api_v1_model_cl_buyer_pb.Buyer.deserializeBinaryFromReader);
      msg.addBuyers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuyersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_cl_buyer_pb.Buyer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated mycargo.projects.erp.api.v1.model.Buyer buyers = 1;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.Buyer>}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.prototype.getBuyersList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.Buyer>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_cl_buyer_pb.Buyer, 1));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.Buyer>} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse} returns this
*/
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.prototype.setBuyersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.Buyer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.Buyer}
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.prototype.addBuyers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mycargo.projects.erp.api.v1.model.Buyer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListBuyersResponse.prototype.clearBuyersList = function() {
  return this.setBuyersList([]);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.services);
