import { ApiUrl, getMetadata } from '../index'
import { ListVehiclesRequest, GetVehicleRequest, UpdateVehicleRequest, UpdateVehiclePayload } from './vehicles_pb'
import { VehiclesClient } from './vehicles_grpc_web_pb'

let client = new VehiclesClient(ApiUrl, null, null);

const vehicle2Model = (vehicle) => {
  let vhcl = new UpdateVehiclePayload()
  vhcl.setCancelled(!!vehicle.cancelled)
 
  return vhcl
}

const API = {
  listVehicles: (params) => {
    const request = new ListVehiclesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params.search) {
      request.setSearch(params.search)
    }
    if (params.nonorderedOnly) {
      request.setNonorderedOnly(params.nonorderedOnly)
    }
    if (params.cancelledOnly) {
      request.setCancelledOnly(params.cancelledOnly)
    }
    if (params.terminalName) {
      request.setTerminalName(params.terminalName)
    }
    return new Promise((resolve, reject) => {
      client.listVehicles(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getVehicle: (name) => {
    const request = new GetVehicleRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getVehicle(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  updateVehicle: (vehicle) => {
    const vhcl = vehicle2Model(vehicle)
    const request = new UpdateVehicleRequest();
    request.setName(vehicle.name)
    request.setVehicle(vhcl)

    return new Promise((resolve, reject) => {
      client.updateVehicle(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
