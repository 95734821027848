// source: projects/erp/service/api/v1/model/invoice.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
var projects_erp_service_api_v1_model_unit_pb = require('../unit_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_unit_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.Invoice', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.InvoiceItem', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.InvoicePayee', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.InvoiceType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.Invoice.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.Invoice.displayName = 'proto.mycargo.projects.erp.api.v1.model.Invoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.InvoiceItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.InvoiceItem.displayName = 'proto.mycargo.projects.erp.api.v1.model.InvoiceItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.InvoiceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.InvoiceType.displayName = 'proto.mycargo.projects.erp.api.v1.model.InvoiceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.InvoicePayee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.InvoicePayee.displayName = 'proto.mycargo.projects.erp.api.v1.model.InvoicePayee';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerDisplayNameActual: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerDetails: jspb.Message.getFieldWithDefault(msg, 6, ""),
    payeeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    invoiceTypeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    parametersMap: (f = msg.getParametersMap()) ? f.toObject(includeInstance, undefined) : [],
    amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    paidAmount: (f = msg.getPaidAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 13, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mycargo.projects.erp.api.v1.model.InvoiceItem.toObject, includeInstance),
    number: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.Invoice;
  return proto.mycargo.projects.erp.api.v1.model.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerDisplayNameActual(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerDetails(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayeeName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceTypeName(value);
      break;
    case 9:
      var value = msg.getParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 10:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 11:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setPaidAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 16:
      var value = new proto.mycargo.projects.erp.api.v1.model.InvoiceItem;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.InvoiceItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerDisplayNameActual();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerDetails();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPayeeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvoiceTypeName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getPaidAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.mycargo.projects.erp.api.v1.model.InvoiceItem.serializeBinaryToWriter
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_display_name_actual = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getCustomerDisplayNameActual = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setCustomerDisplayNameActual = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customer_details = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getCustomerDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setCustomerDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string payee_name = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getPayeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setPayeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string invoice_type_name = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getInvoiceTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setInvoiceTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * map<string, string> parameters = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.clearParametersMap = function() {
  this.getParametersMap().clear();
  return this;};


/**
 * optional Money amount = 10;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 10));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Money paid_amount = 11;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getPaidAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 11));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setPaidAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.clearPaidAmount = function() {
  return this.setPaidAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.hasPaidAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string comment = 13;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp date = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.hasDate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp due_date = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated InvoiceItem items = 16;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.InvoiceItem>}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.InvoiceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.InvoiceItem, 16));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.InvoiceItem>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.mycargo.projects.erp.api.v1.model.InvoiceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string Number = 17;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Invoice} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Invoice.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.InvoiceItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: (f = msg.getPrice()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    quantity: (f = msg.getQuantity()) && projects_erp_service_api_v1_model_unit_pb.Unit.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.InvoiceItem;
  return proto.mycargo.projects.erp.api.v1.model.InvoiceItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemName(value);
      break;
    case 6:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 7:
      var value = new projects_erp_service_api_v1_model_unit_pb.Unit;
      reader.readMessage(value,projects_erp_service_api_v1_model_unit_pb.Unit.deserializeBinaryFromReader);
      msg.setQuantity(value);
      break;
    case 8:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.InvoiceItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      projects_erp_service_api_v1_model_unit_pb.Unit.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string order_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setOrderName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string item_name = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Money price = 6;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getPrice = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 6));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
*/
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Unit quantity = 7;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Unit}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getQuantity = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Unit} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_unit_pb.Unit, 7));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Unit|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
*/
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.clearQuantity = function() {
  return this.setQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.hasQuantity = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Money amount = 8;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 8));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
*/
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceItem} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceItem.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.InvoiceType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentHeaderComment: jspb.Message.getFieldWithDefault(msg, 4, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.InvoiceType;
  return proto.mycargo.projects.erp.api.v1.model.InvoiceType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentHeaderComment(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.InvoiceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentHeaderComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_header_comment = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.getDocumentHeaderComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.setDocumentHeaderComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string logo_url = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_active = 6;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoiceType} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoiceType.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.InvoicePayee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bankDisplayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bankPayeeDisplayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bankPayeeAddressDisplayName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    swift: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bankPayeeAccountDisplayName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    additionalRequisites: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bankRoutingNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.InvoicePayee;
  return proto.mycargo.projects.erp.api.v1.model.InvoicePayee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankPayeeDisplayName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankPayeeAddressDisplayName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwift(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankPayeeAccountDisplayName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalRequisites(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankRoutingNumber(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.InvoicePayee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBankDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankPayeeDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBankPayeeAddressDisplayName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSwift();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBankPayeeAccountDisplayName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAdditionalRequisites();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBankRoutingNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bank_display_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getBankDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setBankDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_payee_display_name = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getBankPayeeDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setBankPayeeDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bank_payee_address_display_name = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getBankPayeeAddressDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setBankPayeeAddressDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_name = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string swift = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getSwift = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setSwift = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string bank_payee_account_display_name = 9;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getBankPayeeAccountDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setBankPayeeAccountDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string additional_requisites = 10;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getAdditionalRequisites = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setAdditionalRequisites = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string bank_routing_number = 11;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getBankRoutingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setBankRoutingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_active = 12;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.InvoicePayee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.InvoicePayee.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.model);
