import { ApiUrl, getMetadata } from '../index'
import { Document } from './document_pb'
import {
  ListDocumentsRequest,
  GetDocumentRequest,
  CreateDocumentRequest,
  DeleteDocumentRequest,
} from './documents_pb'
import { DocumentsClient } from './documents_grpc_web_pb'

// ** Utility
let client = new DocumentsClient(ApiUrl, null, null);

function base64ToUint8Array(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}

const document2Model = (document) => {
  let doc = new Document()
  doc.setDisplayName(document.displayName)
  doc.setLocation(document.location)
  doc.setUrl(document.url)
  doc.setContentType(document.contentType)
  doc.setContent(base64ToUint8Array(document.content))

  return doc
}

const API = {
  listDocuments: (params) => {
    const request = new ListDocumentsRequest()
    request.setLocation(params?.location ?? "")
    request.setUrl(params?.url ?? "")
    return new Promise((resolve, reject) => {
      client.listDocuments(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().documentsList)
        }
      });
    });
  },
  getDocument: (name) => {
    const request = new GetDocumentRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getDocument(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  createDocument: (document) => {
    const doc = document2Model(document)
    const request = new CreateDocumentRequest()
    request.setDocument(doc)

    return new Promise((resolve, reject) => {
      client.createDocument(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  deleteDocument: (name) => {
    const request = new DeleteDocumentRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteDocument(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
