// ** Icons Import
import {Globe, Map, MapPin, Navigation2} from 'react-feather'

export default [
  {
    header: 'Locations',
    resource: 'locations',
    action: 'read'
  },
  {
    id: 'terminals',
    title: 'Terminals',
    icon: <Globe size={20} />,
    navLink: '/terminals',
    resource: 'terminals',
    action: 'read'
  },
  {
    id: 'cities',
    title: 'Cities',
    icon: <MapPin size={20} />,
    navLink: '/cities',
    resource: 'cities',
    action: 'read'
  },
  {
    id: 'states',
    title: 'States',
    icon: <Navigation2 size={20} />,
    navLink: '/states',
    resource: 'states',
    action: 'read'
  },
  {
    id: 'countries',
    title: 'Countries',
    icon: <Map size={20} />,
    navLink: '/countries',
    resource: 'countries',
    action: 'read'
  },
]
