/**
 * @fileoverview gRPC-Web generated client stub for mycargo.projects.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/customers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_customer_pb = require('./customer_pb.js')
const proto = {};
proto.mycargo = {};
proto.mycargo.projects = {};
proto.mycargo.projects.erp = {};
proto.mycargo.projects.erp.api = {};
proto.mycargo.projects.erp.api.v1 = {};
proto.mycargo.projects.erp.api.v1.services = require('./customers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.CustomersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.CustomersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListCustomersRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListCustomersResponse>}
 */
const methodDescriptor_Customers_ListCustomers = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Customers/ListCustomers',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListCustomersRequest,
  proto.mycargo.projects.erp.api.v1.services.ListCustomersResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListCustomersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListCustomersResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListCustomersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListCustomersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListCustomersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.CustomersClient.prototype.listCustomers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/ListCustomers',
      request,
      metadata || {},
      methodDescriptor_Customers_ListCustomers,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListCustomersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListCustomersResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.CustomersPromiseClient.prototype.listCustomers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/ListCustomers',
      request,
      metadata || {},
      methodDescriptor_Customers_ListCustomers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetCustomerRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Customer>}
 */
const methodDescriptor_Customers_GetCustomer = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Customers/GetCustomer',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetCustomerRequest,
  projects_erp_service_api_v1_model_customer_pb.Customer,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_pb.Customer.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Customer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Customer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.CustomersClient.prototype.getCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_GetCustomer,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Customer>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.CustomersPromiseClient.prototype.getCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_GetCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.CreateCustomerRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Customer>}
 */
const methodDescriptor_Customers_CreateCustomer = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Customers/CreateCustomer',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.CreateCustomerRequest,
  projects_erp_service_api_v1_model_customer_pb.Customer,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.CreateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_pb.Customer.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Customer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Customer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.CustomersClient.prototype.createCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/CreateCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_CreateCustomer,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Customer>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.CustomersPromiseClient.prototype.createCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/CreateCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_CreateCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateCustomerRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Customer>}
 */
const methodDescriptor_Customers_UpdateCustomer = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Customers/UpdateCustomer',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateCustomerRequest,
  projects_erp_service_api_v1_model_customer_pb.Customer,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_pb.Customer.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Customer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Customer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.CustomersClient.prototype.updateCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/UpdateCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_UpdateCustomer,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Customer>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.CustomersPromiseClient.prototype.updateCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/UpdateCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_UpdateCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteCustomerRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.DeleteCustomerResponse>}
 */
const methodDescriptor_Customers_DeleteCustomer = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Customers/DeleteCustomer',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.DeleteCustomerRequest,
  proto.mycargo.projects.erp.api.v1.services.DeleteCustomerResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.DeleteCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.DeleteCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.DeleteCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.CustomersClient.prototype.deleteCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/DeleteCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_DeleteCustomer,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.DeleteCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.CustomersPromiseClient.prototype.deleteCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Customers/DeleteCustomer',
      request,
      metadata || {},
      methodDescriptor_Customers_DeleteCustomer);
};


module.exports = proto.mycargo.projects.erp.api.v1.services;

