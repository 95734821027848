/**
 * @fileoverview gRPC-Web generated client stub for mycargo.projects.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/vehicles.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_vehicle_pb = require('./vehicle_pb.js')
const proto = {};
proto.mycargo = {};
proto.mycargo.projects = {};
proto.mycargo.projects.erp = {};
proto.mycargo.projects.erp.api = {};
proto.mycargo.projects.erp.api.v1 = {};
proto.mycargo.projects.erp.api.v1.services = require('./vehicles_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest,
 *   !proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse>}
 */
const methodDescriptor_Vehicles_ListVehicles = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Vehicles/ListVehicles',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest,
  proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesClient.prototype.listVehicles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Vehicles/ListVehicles',
      request,
      metadata || {},
      methodDescriptor_Vehicles_ListVehicles,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesPromiseClient.prototype.listVehicles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Vehicles/ListVehicles',
      request,
      metadata || {},
      methodDescriptor_Vehicles_ListVehicles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Vehicle>}
 */
const methodDescriptor_Vehicles_GetVehicle = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Vehicles/GetVehicle',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest,
  projects_erp_service_api_v1_model_vehicle_pb.Vehicle,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_vehicle_pb.Vehicle.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Vehicle)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Vehicle>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesClient.prototype.getVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Vehicles/GetVehicle',
      request,
      metadata || {},
      methodDescriptor_Vehicles_GetVehicle,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Vehicle>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesPromiseClient.prototype.getVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Vehicles/GetVehicle',
      request,
      metadata || {},
      methodDescriptor_Vehicles_GetVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest,
 *   !proto.mycargo.projects.erp.api.v1.model.Vehicle>}
 */
const methodDescriptor_Vehicles_UpdateVehicle = new grpc.web.MethodDescriptor(
  '/mycargo.projects.erp.api.v1.services.Vehicles/UpdateVehicle',
  grpc.web.MethodType.UNARY,
  proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest,
  projects_erp_service_api_v1_model_vehicle_pb.Vehicle,
  /**
   * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_vehicle_pb.Vehicle.deserializeBinary
);


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mycargo.projects.erp.api.v1.model.Vehicle)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mycargo.projects.erp.api.v1.model.Vehicle>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesClient.prototype.updateVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Vehicles/UpdateVehicle',
      request,
      metadata || {},
      methodDescriptor_Vehicles_UpdateVehicle,
      callback);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mycargo.projects.erp.api.v1.model.Vehicle>}
 *     Promise that resolves to the response
 */
proto.mycargo.projects.erp.api.v1.services.VehiclesPromiseClient.prototype.updateVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mycargo.projects.erp.api.v1.services.Vehicles/UpdateVehicle',
      request,
      metadata || {},
      methodDescriptor_Vehicles_UpdateVehicle);
};


module.exports = proto.mycargo.projects.erp.api.v1.services;

