// source: projects/erp/service/api/v1/model/shipment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
var projects_erp_service_api_v1_model_vehicle_pb = require('../vehicles/vehicle_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_vehicle_pb);
var projects_erp_service_api_v1_model_unit_pb = require('../unit_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_unit_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.Shipment', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.ShipmentContainer', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.model.ShipmentUnit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.Shipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.model.Shipment.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.Shipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.Shipment.displayName = 'proto.mycargo.projects.erp.api.v1.model.Shipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.ShipmentContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.displayName = 'proto.mycargo.projects.erp.api.v1.model.ShipmentContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.displayName = 'proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.model.ShipmentUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.displayName = 'proto.mycargo.projects.erp.api.v1.model.ShipmentUnit';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.Shipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.Shipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    container: (f = msg.getContainer()) && proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.toObject(includeInstance, f),
    consignee: (f = msg.getConsignee()) && proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.toObject(includeInstance, f),
    unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
    proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.toObject, includeInstance),
    photosMap: (f = msg.getPhotosMap()) ? f.toObject(includeInstance, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.toObject) : [],
    isArchive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    consigneeCustomerName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    needDiscount: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    discountAmount: (f = msg.getDiscountAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.Shipment;
  return proto.mycargo.projects.erp.api.v1.model.Shipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Shipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.mycargo.projects.erp.api.v1.model.ShipmentContainer;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.deserializeBinaryFromReader);
      msg.setContainer(value);
      break;
    case 4:
      var value = new proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.deserializeBinaryFromReader);
      msg.setConsignee(value);
      break;
    case 5:
      var value = new proto.mycargo.projects.erp.api.v1.model.ShipmentUnit;
      reader.readMessage(value,proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.deserializeBinaryFromReader);
      msg.addUnits(value);
      break;
    case 6:
      var value = msg.getPhotosMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.mycargo.projects.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArchive(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsigneeCustomerName(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedDiscount(value);
      break;
    case 10:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setDiscountAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.Shipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Shipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getContainer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.serializeBinaryToWriter
    );
  }
  f = message.getConsignee();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.serializeBinaryToWriter
    );
  }
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.serializeBinaryToWriter
    );
  }
  f = message.getPhotosMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.mycargo.projects.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getIsArchive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getConsigneeCustomerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNeedDiscount();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDiscountAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ShipmentContainer container = 3;
 * @return {?proto.mycargo.projects.erp.api.v1.model.ShipmentContainer}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getContainer = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.ShipmentContainer, 3));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.ShipmentContainer|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setContainer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.clearContainer = function() {
  return this.setContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.hasContainer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ShipmentConsignee consignee = 4;
 * @return {?proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getConsignee = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} */ (
    jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee, 4));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setConsignee = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.clearConsignee = function() {
  return this.setConsignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.hasConsignee = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ShipmentUnit units = 5;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit>}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mycargo.projects.erp.api.v1.model.ShipmentUnit, 5));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.mycargo.projects.erp.api.v1.model.ShipmentUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};


/**
 * map<string, ListOfStrings> photos = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.mycargo.projects.erp.api.v1.model.ListOfStrings>}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getPhotosMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.mycargo.projects.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.mycargo.projects.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.clearPhotosMap = function() {
  this.getPhotosMap().clear();
  return this;};


/**
 * optional bool is_archive = 7;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getIsArchive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setIsArchive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string consignee_customer_name = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getConsigneeCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setConsigneeCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool need_discount = 9;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getNeedDiscount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setNeedDiscount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Money discount_amount = 10;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.getDiscountAmount = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 10));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
*/
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Shipment} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.clearDiscountAmount = function() {
  return this.setDiscountAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Shipment.prototype.hasDiscountAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    shippingLine: jspb.Message.getFieldWithDefault(msg, 1, ""),
    aesitn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preCarriage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    placeOfReceipt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loadingPort: jspb.Message.getFieldWithDefault(msg, 5, ""),
    containerNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bookingNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cutOff: (f = msg.getCutOff()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    departure: (f = msg.getDeparture()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    arrival: (f = msg.getArrival()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pierTerminal: jspb.Message.getFieldWithDefault(msg, 11, ""),
    portOfDischarge: jspb.Message.getFieldWithDefault(msg, 12, ""),
    sealNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.ShipmentContainer;
  return proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShippingLine(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAesitn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreCarriage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceOfReceipt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoadingPort(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContainerNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingNo(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCutOff(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeparture(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setArrival(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPierTerminal(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortOfDischarge(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSealNumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShippingLine();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAesitn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreCarriage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlaceOfReceipt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoadingPort();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContainerNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBookingNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCutOff();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeparture();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArrival();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPierTerminal();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPortOfDischarge();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSealNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string shipping_line = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getShippingLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setShippingLine = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string aesitn = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getAesitn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setAesitn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_carriage = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getPreCarriage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setPreCarriage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string place_of_receipt = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getPlaceOfReceipt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setPlaceOfReceipt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string loading_port = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getLoadingPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setLoadingPort = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string container_no = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getContainerNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setContainerNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string booking_no = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getBookingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setBookingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp cut_off = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getCutOff = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
*/
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setCutOff = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.clearCutOff = function() {
  return this.setCutOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.hasCutOff = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp departure = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getDeparture = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
*/
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.clearDeparture = function() {
  return this.setDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.hasDeparture = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp arrival = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getArrival = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
*/
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setArrival = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.clearArrival = function() {
  return this.setArrival(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.hasArrival = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string pier_terminal = 11;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getPierTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setPierTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string port_of_discharge = 12;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getPortOfDischarge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setPortOfDischarge = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string seal_number = 13;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getSealNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setSealNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string equipment = 14;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentContainer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentContainer.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee;
  return proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentConsignee.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lotNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    weight: (f = msg.getWeight()) && projects_erp_service_api_v1_model_unit_pb.Unit.toObject(includeInstance, f),
    orderName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.model.ShipmentUnit;
  return proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLotNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 6:
      var value = new projects_erp_service_api_v1_model_unit_pb.Unit;
      reader.readMessage(value,projects_erp_service_api_v1_model_unit_pb.Unit.deserializeBinaryFromReader);
      msg.setWeight(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLotNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWeight();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      projects_erp_service_api_v1_model_unit_pb.Unit.serializeBinaryToWriter
    );
  }
  f = message.getOrderName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string lot_number = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getLotNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setLotNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string vin = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Unit weight = 6;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Unit}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getWeight = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Unit} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_unit_pb.Unit, 6));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Unit|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
*/
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setWeight = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.clearWeight = function() {
  return this.setWeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.hasWeight = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string order_name = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setOrderName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string customer_name = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.ShipmentUnit} returns this
 */
proto.mycargo.projects.erp.api.v1.model.ShipmentUnit.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.model);
