import { ApiUrl, getMetadata } from '../index'
import { ListShipmentsRequest, GetShipmentRequest } from './shipments_pb'
import { ShipmentsClient } from './shipments_grpc_web_pb'

let client = new ShipmentsClient(ApiUrl, null, null);

const API = {
  listShipments: (params) => {
    const request = new ListShipmentsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params.search) {
      request.setSearch(params.search)
    }
    return new Promise((resolve, reject) => {
      client.listShipments(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getShipment: (name) => {
    const request = new GetShipmentRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getShipment(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
