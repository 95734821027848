import {createContext, useContext, useState} from 'react';

const PaymentContext = createContext(undefined);
/* created context only for payments..........*/
export const PaymentProvider = ({children}) => {
    const [payment, setPayment] = useState();
    const [valid, setValid] = useState(true)

    const updatePayment = (newPaymentOrFunction) => {
        setPayment((prevPayment) => {
            return typeof newPaymentOrFunction === 'function'
                ? newPaymentOrFunction(prevPayment)
                : newPaymentOrFunction;
        });
    };

    const contextValues =
        {
            payment,
            updatePayment,
            valid,
            setValid,
        }

    return (
        <div className="app-content content overflow-hidden">
            <div className="content-overlay"/>
            <div className="header-navbar-shadow"/>
            <div className="content-wrapper container-xxl p-0">
                <PaymentContext.Provider value={contextValues}>
                    {children}
                </PaymentContext.Provider>
            </div>
        </div>
    );
};

export const usePayment = (initialPayment) => {
    const context = useContext(PaymentContext);
    if (!context) {
        throw new Error('usePayment must be used within a PaymentProvider');
    }

    if (initialPayment !== undefined && context.payment === undefined) {
        context.updatePayment(initialPayment);
    }

    return context;
};
