// ** Icons Import
import { User, UserX } from 'react-feather'

export default [
  {
    header: 'Users',
    resource: 'users',
    action: 'read'
  },
  {
    id: 'customers',
    title: 'Customers',
    icon: <User size={20} />,
    navLink: '/customers',
    resource: 'users',
    action: 'read'
  },
  {
    id: 'suspended',
    title: 'Suspended',
    icon: <UserX size={20} />,
    navLink: '/suspended',
    resource: 'users',
    action: 'read'
  }
]
