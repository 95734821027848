// ** React Imports
import { lazy } from 'react'

const CitiesList = lazy(() => import('views/cities/list'))
const CountriesList = lazy(() => import('views/countries/list'))
const TerminalList = lazy(() => import('views/locations/terminals/list'))
const StatesList = lazy(() => import('views/states/list'))

const LocationsRoutes = [
  {
    element: <TerminalList />,
    path: '/terminals',
    meta: {
      resource: 'terminals',
      action: 'read'
    }
  },
  {
    element: <CitiesList />,
    path: '/cities',
    meta: {
      resource: 'cities',
      action: 'read'
    }
  },
  {
    element: <StatesList />,
    path: '/states',
    meta: {
      resource: 'states',
      action: 'read'
    }
  },
  {
    element: <CountriesList />,
    path: '/countries',
    meta: {
      resource: 'countries',
      action: 'read'
    }
  }
]

export default LocationsRoutes
