import { ApiUrl, getMetadata } from '../index'
import { Approval } from './approval_pb'
import {
  ListApprovalsRequest,
  CreateApprovalRequest,
  DeleteApprovalRequest
} from './approvals_pb'
import { ApprovalsClient } from './approvals_grpc_web_pb'

let client = new ApprovalsClient(ApiUrl, null, null);

const approval2Model = (approval) => {
  let apr = new Approval()
  apr.setEntityName(approval.entityName ?? '')
 
  return apr
}

const API = {
  listApprovals: (params) => {
    const request = new ListApprovalsRequest()
    if (params?.entityName) {
      request.setEntityName(params.entityName)
    }
    return new Promise((resolve, reject) => {
      client.listApprovals(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().approvalsList)
        }
      })
    })
  },
  createApproval: (approval) => {
    const apr = approval2Model(approval)
    const request = new CreateApprovalRequest()
    request.setApproval(apr)

    return new Promise((resolve, reject) => {
      client.createApproval(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteApproval: (name) => {
    const request = new DeleteApprovalRequest();
    request.setName(name)

    return new Promise((resolve, reject) => {
      client.deleteApproval(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
}

export default API
