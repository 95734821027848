// source: projects/erp/service/api/v1/services/formulas.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var projects_erp_service_api_v1_model_formula_pb = require('./formula_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_formula_pb);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse', null, global);
goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.repeatedFields_, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.displayName = 'proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest;
  return proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    formulasList: jspb.Message.toObjectList(msg.getFormulasList(),
    projects_erp_service_api_v1_model_formula_pb.Formula.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse;
  return proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_formula_pb.Formula;
      reader.readMessage(value,projects_erp_service_api_v1_model_formula_pb.Formula.deserializeBinaryFromReader);
      msg.addFormulas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_formula_pb.Formula.serializeBinaryToWriter
    );
  }
};


/**
 * repeated mycargo.projects.erp.api.v1.model.Formula formulas = 1;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.Formula>}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.prototype.getFormulasList = function() {
  return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.Formula>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_formula_pb.Formula, 1));
};


/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.Formula>} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse} returns this
*/
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.prototype.setFormulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.Formula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.Formula}
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.prototype.addFormulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mycargo.projects.erp.api.v1.model.Formula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListFormulasResponse.prototype.clearFormulasList = function() {
  return this.setFormulasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest;
  return proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.GetFormulaRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    formula: (f = msg.getFormula()) && projects_erp_service_api_v1_model_formula_pb.Formula.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest;
  return proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_formula_pb.Formula;
      reader.readMessage(value,projects_erp_service_api_v1_model_formula_pb.Formula.deserializeBinaryFromReader);
      msg.setFormula(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormula();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_formula_pb.Formula.serializeBinaryToWriter
    );
  }
};


/**
 * optional mycargo.projects.erp.api.v1.model.Formula formula = 1;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Formula}
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.prototype.getFormula = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Formula} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_formula_pb.Formula, 1));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Formula|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} returns this
*/
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.prototype.setFormula = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.prototype.clearFormula = function() {
  return this.setFormula(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.services.CreateFormulaRequest.prototype.hasFormula = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    formula: (f = msg.getFormula()) && projects_erp_service_api_v1_model_formula_pb.Formula.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest;
  return proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new projects_erp_service_api_v1_model_formula_pb.Formula;
      reader.readMessage(value,projects_erp_service_api_v1_model_formula_pb.Formula.deserializeBinaryFromReader);
      msg.setFormula(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormula();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      projects_erp_service_api_v1_model_formula_pb.Formula.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional mycargo.projects.erp.api.v1.model.Formula formula = 2;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Formula}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.getFormula = function() {
  return /** @type{?proto.mycargo.projects.erp.api.v1.model.Formula} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_formula_pb.Formula, 2));
};


/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Formula|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} returns this
*/
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.setFormula = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.clearFormula = function() {
  return this.setFormula(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateFormulaRequest.prototype.hasFormula = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest;
  return proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse;
  return proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.DeleteFormulaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.services);
