// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/cargoloop'

export const list = createAsyncThunk('appBuyers/list', async () => {
  return await API.getBuyers()
})

export const appBuyersSlice = createSlice({
  name: 'appBuyers',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appBuyersSlice.reducer
