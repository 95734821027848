// ** Navigation imports
import financial from './financial'
import ordering from './ordering'
import users from './users'
import locations from './locations'
import cargoloop from './cargoloop'
import references from './references'

// ** Merge & Export
export default [...users, ...ordering, ...financial, ...cargoloop, ...locations, ...references]
